import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";
import {
  BasicTextField,
  EntryTypeField,
  EnumerableField,
  EnumerablesField,
  LEGAL_ENTITY,
  NATURAL_PERSON,
} from "../../../../forms";
import { createEntityFormValuesInitialValue, CreateEntityFormValues } from ".";
import { createEntityFormValidationSchema } from "./validations";

import { useTranslation } from "react-i18next";
import { TenantField } from "../../../../forms/elements/simple/tenant/Tenant";
import { PermissionGuard, usePermissions } from "../../../../models/auth";
import {
  ApiCreateEntity,
  ApiEntity,
  mapCreateEntityFormValuesToApiCreateEntity,
  useCreateEntityMutation,
} from "../../../../models/entities";
import { TAG, TITLE } from "../../../../models/enumerations";
import { useNavigate } from "react-router-dom";
interface CreateEntityFormProps {
  onClose: () => void;
}
export const CreateEntityForm: FC<CreateEntityFormProps> = ({ onClose }) => {
  const [createEntity] = useCreateEntityMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  return (
    <Formik<CreateEntityFormValues>
      enableReinitialize
      initialValues={{
        ...createEntityFormValuesInitialValue(),
      }}
      validationSchema={createEntityFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiCreateEntity: ApiCreateEntity =
            mapCreateEntityFormValuesToApiCreateEntity(values);
          const apiEntity: ApiEntity = await createEntity(
            apiCreateEntity
          ).unwrap();

          toast.success(t("common.recordCreatedSuccessfully"));
          navigate(`./${apiEntity.id}`);
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.createRecord")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <PermissionGuard permission="ADMIN">
                <Field
                  name={`tenantIdForHeader`}
                  component={TenantField}
                  label="common.tenant"
                  // size="small"
                />
              </PermissionGuard>

              <Field
                name={`tags`}
                component={EnumerablesField}
                type={TAG}
                multiple
                margin="normal"
              />

              <Field
                name={`entryType`}
                component={EntryTypeField}
                margin="normal"
              />

              {getIn(values, "entryType") === LEGAL_ENTITY && (
                <Field
                  name={`legalEntityName`}
                  component={BasicTextField}
                  label="common.legalEntityName"
                  margin="normal"
                />
              )}

              {getIn(values, "entryType") === NATURAL_PERSON && (
                <>
                  <Field
                    name={`title`}
                    component={EnumerableField}
                    type={TITLE}
                    margin="normal"
                  />
                  <Field
                    name={`firstName`}
                    component={BasicTextField}
                    label="common.firstName"
                    margin="normal"
                  />
                  <Field
                    name={`lastName`}
                    component={BasicTextField}
                    label="common.lastName"
                    margin="normal"
                  />
                </>
              )}

              <Field
                name={`additionalComments`}
                component={BasicTextField}
                label="common.additionalComments"
                // size="small"
                margin="normal"
                info="common.refeeredByOrKnownAs"
              />

              <Box sx={{ mt: "24px" }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t("common.create")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
