import { Color, colors } from "@mui/material";
import { ApiInvoiceStatus } from ".";

export const INITIAL_INVOICE_STATUS = 'INITIAL';
export const DRAFT_INVOICE_STATUS = 'DRAFT';
export const PENDING_APPROVAL_INVOICE_STATUS = 'PENDING_APPROVAL';
export const APPROVED_INVOICE_STATUS = 'APPROVED';
export const SENT_INVOICE_STATUS = 'SENT';
export const NOT_SENT_INVOICE_STATUS = 'NOT_SENT';
export const FINAL_INVOICE_STATUS = 'FINAL';



export const invoiceStatuses = [INITIAL_INVOICE_STATUS,
    DRAFT_INVOICE_STATUS,
    PENDING_APPROVAL_INVOICE_STATUS,
    APPROVED_INVOICE_STATUS,
    SENT_INVOICE_STATUS,
    NOT_SENT_INVOICE_STATUS,
    FINAL_INVOICE_STATUS] as const;
export const invoiceStatusesColours = [colors.orange, colors.orange, colors.orange, colors.green, colors.green, colors.red, colors.green];
export const invoiceStatusesColourMap = new Map<ApiInvoiceStatus, Color>(invoiceStatuses.map((v, i) => [v, invoiceStatusesColours[i]]));;
