import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ApiReceiptStatus, receiptStatuses, receiptStatusesColourMap } from ".";
import { StatusLabel } from "../../../../components/StatusLabel";

export interface ReceiptStatusFieldProps {
  disabled: boolean;
  label?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense";
  onChange: (value: string) => void;
}
export const ReceiptStatusField: FC<
  ReceiptStatusFieldProps & FieldProps<string, string>
> = ({
  disabled = false,
  label = "common.receiptStatus",
  size = "medium",
  margin,
  onChange = () => null,
  field,
  form: { values, touched, errors, handleBlur, handleChange, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth margin={margin}>
        <InputLabel
          htmlFor={field.name}
          error={getIn(touched, field.name) && !!getIn(errors, field.name)}
        >
          {t(label)}
        </InputLabel>
        <Field
          component={Select}
          name={field.name}
          fullWidth
          margin={margin}
          variant="outlined"
          label={t(label)}
          value={getIn(values, field.name)}
          error={Boolean(
            getIn(touched, field.name) && !!getIn(errors, field.name)
          )}
          onBlur={handleBlur(field.name)}
          onChange={(e: any, value: ApiReceiptStatus) => {
            handleChange(field.name)(e);
            onChange(value);
          }}
          disabled={disabled}
          size={size}
        >
          {receiptStatuses.map((i) => (
            <MenuItem key={i} value={i}>
              <StatusLabel<ApiReceiptStatus>
                colourMap={receiptStatusesColourMap}
                status={i}
              />
            </MenuItem>
          ))}
        </Field>
        <FormHelperText error>
          {getIn(touched, field.name) && t(getIn(errors, field.name))}
        </FormHelperText>
      </FormControl>
    </>
  );
};
