import { ApiMoney, Money } from ".";
import { ApiEnumeration, CURRENCY } from "../../../../models/enumerations";

export const mapMoneyToApiMoney = (money: Money): ApiMoney => {
    return {
        value: money.value,
        currency: money.currency?.value!
    }
}

export const mapApiMoneyToMoney = (apiMoney: ApiMoney, enumerations: ApiEnumeration[]): Money => {
    return {
        value: apiMoney.value != null ? apiMoney.value : '',
        currency: apiMoney.currency ? enumerations.filter(e => e.type === CURRENCY).find(x => x.value === apiMoney.currency)! : enumerations.filter(e => e.type === CURRENCY).find(x => x.value === 'EUR')!,
    }
}