import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          common: {
            invoiceSentForApproval: 'Invoice sent for approval',
            sharepointHyperlinkOfTheFiscalReceipt: 'Sharepoint hyperlink of the fiscal receipt',
            manualReceiptNumber: 'Manual receipt number',
            wasAManualReceiptSentToCustomer: 'Was a manual receipt sent to customer?',
            receiptMarkedAsSentSuccessfully: ' Receipt marked as sentr successfully',
            reasonForReceiptNotSent: 'Reason for receipt not sent',
            receiptMarkedAsNotSentSuccessfully: 'Receipt marked as not sent successfully',
            dateWhenEmailWasSentToCustomer: 'Date when e-mail was sent to customer',
            wasReceiptSubmittedToTheCustomer: 'Was receipt submitted to the customer?',
            sendReceiptToCustomer: 'Send receipt to customer',
            paidAmountAllocation: 'Paid amount allocation',
            receiptAllocationDetails: 'Receipt allocation details',
            reasonForInvoiceNotSent: 'Reason for invoice not sent',
            invoiceMarkedAsNotSentSuccessfully: 'Invoice marked as not sent successfully',
            invoiceMarkedAsSentSuccessfully: 'Invoice marked as sent successfully',
            updateAndSendForApproval: 'Update and Send for Approval',
            createAndSendForApproval: 'Create and Send for Approval',
            cancelInvoice: 'Cancel Invoice',
            serviceDescription: 'Service Description',
            amountExclVat: 'Amount (excl. VAT)',
            unallocatedAmount: 'Unallocated Amount',
            receiptCanceledSuccessfully: 'Receipt cancelelled successfully',
            cancelReceipt: 'Cancel Receipt',
            allocationStatus: 'Allocation Status',
            receiptCreatedSuccessfully: 'Receipt created successfully',
            amountAllocatedToInvoices: 'Amount allocated to invoices',
            remainingAmountNotAllocated: 'Remaining amount not allocated',
            paidAmountToAllocate: 'Paid amount to allocate',
            receiptAmount: 'Receipt Amount',
            receiptDate: 'Receipt Date',
            creditNote: 'Credit Note',
            creditNoteallocatedSuccessfully: 'Credit Note Alllocated Successfully',
            remainingAvailableBalance: 'Remainng Available Balance',
            allocatedCredit: 'Allocated Credit',
            amountToAllocate: 'Amount to allocate',
            availableCredit: 'Available Credit',
            creditNoteRefNo: 'Credit Note Ref No',
            allocateCreditNote: 'Allocate Credit Note',
            creditAmountToAllocate: 'Credit Amount to Allocate',
            invoiceAmountInclVat: 'Invoice Amount (Incl. VAT)',
            allocationOfCreditNoteToInvoices: 'Allocation of Credit Note to Invoice/s',
            editCreditNote: 'Edit Credit Note',
            updateCreditNote: 'Update Credit Note',
            creditNoteUpdatedSuccessfully: 'Credit Note updated successfully',
            invoiceUpdatedSuccessfully: 'Invoice updated successfully',
            linkedINVCNRefNo: 'Linked INV / CN Ref No',
            submissionDate: 'Submission Date',
            issuedBy: 'Issued By',
            cnAllocationAmount: 'CN Allocation Amount',
            cn: 'CN',
            creditNoteSentForApproval: 'Credit Note sent for approval',
            creditNoteCreatedSuccessfully: 'Credit Note created successfully',
            creditNoteManualInputs: 'Credit Note Manual Inputs',
            creditNoteTotal: 'Credit Note Total',
            creditNoteDetails: 'Credit Note Details',
            creditNoteDate: 'Credit Note Date',
            createCreditNote: 'Create Credit Note',
            balanceDue: 'Balance due',
            balanceDueByCustomer: 'Balance due by customer',
            total: 'Total',
            loss: 'Loss',
            profit: 'Profit',
            invoicesIssuedExclVAT: 'Invoices issued (excl VAT)',
            invoicedTSEntries: 'Invoiced T/S Entries',
            uninvoicedTSEntries: 'Uninvoiced T/S Entries',
            writeOffs: 'Write-offs',
            invoicingStatistics: 'Invoicing statistics',
            invoiceMarkedAsFinalSuccessfully: 'Invoice marked as final successfully',
            invoiceCannotBeUpdatedIfNotSent: 'Invoice cannot be updated if not sent',
            wasInvoiceSubmittedToTheCustomer: 'Was invoice submitted to the customer ?',
            markAsFinal: 'Mark as final',
            invoiceApprovedSuccessfully: 'Invoice approved successfully',
            invoiceSentBackToDraftSuccessfully: 'Invoice sent back to draft successfully',
            sendInvoiceToCustomer: 'Send invoice to customer',
            pendingInvoicesForApproval: 'Pending Invoices For Approval',
            invoicesRequiringApproval: 'Invoices Requiring Approval',
            invoiceCreatedSuccessfully: 'Invoice Created Successfully',
            finalizedBy: 'Finalized By',
            timesheetAllocations: 'Timesheet Allocations',
            subTotalExclVat: 'Sub-total (excl Vat)',
            VAT: 'VAT',
            invoiceTotalInclVat: 'Invoice total (incl Vat)',
            invoiceTotalExclVat: 'Invoice total (excl Vat)',
            amountPaid: 'Amount Paid',
            CNAllocation: 'CN Allocation',
            balanceDueOnInvoice: 'Balance due on Invoice',
            finalziedBy: 'Finalized By',
            paymentStatus: 'Payment Status',
            linkedReceipt: 'Linked Receipt',
            linkedCN: 'Linked CN',
            linkedIN: 'Linked IN',
            finalizedDate: 'Finalized Date',
            invoiceTotal: 'Invoice total',
            remove: 'Remove',
            add: 'Add',
            wy: 'WY',
            totalTsEntriesAllocated: 'Total T/S Entries allocated',
            tsEntriesAllocated: 'T/S Entries allocated',
            tsEntriesUnallocated: 'T/S Entries unallocated',
            amountToCharge: 'Amount to charge',
            em: 'Em',
            time: 'Time',
            chargeOut: 'Charge-out',
            collapseAll: 'Collapse All',
            expandAll: 'Expand All',
            invoiceDate: 'Invoice Date',
            statusDate: 'Status Date',
            dateApprovedOn: 'Date Approved On',
            quoteSentOn: 'Quote sent to customer on',
            changeApprover: 'Change Approver',
            saveChanges: 'Save Changes',
            saveAndApprove: 'Save and Approve',
            rejectApproval: 'Reject Approval',
            formIsEmpty: 'Form is empty',
            acceptedSharepointLinkUpdatedSuccessfully: 'Accepted Sharepoint Link Updated Successfully',
            rejectedSharepointLinkUpdatedSuccessfully: 'Rejected Sharepoint Link Updated Successfully',
            sentSharepointLinkUpdatedSuccessfully: 'Sent Sharepoint Link Updated Successfully',
            resolveAllErrorsPriorToSubmission: 'Errors must be resolved prior to submission',
            sentSharepointLink: 'Sent to customer email sharepoint link',
            acceptedSharepointLink: 'Accepted email sharepoint link',
            rejectedSharepointLink: 'Rejected email sharepoint link',
            link: 'Link',
            requestedBy: 'Requested By',
            reasonForApprovalRejection: 'Reason for approval rejection',
            pendingQuotationsForApproval: 'Pending Quotations for Approval',
            quotationsRequiringApproval: 'Quotations requiring Approval',
            quoteCancellationReason: 'Quote cancellation reason',
            receiptCancellationReason: 'Receipt cancellation reason',
            receiptCancellationReasonDetails: 'Receipt cancellation reason details',
            quoteCancellationReasonDetails: 'Quote cancellation eason details',
            receiptCancellationReasonManagement: 'Receipt cancellation reason management',
            quoteCancellationReasonManagement: 'Quote cancellation reason management',
            receiptCancellationReasons: 'Receipt cancellation reasons',
            quoteCancellationReasons: 'Quote cancellation reasons',
            editReceiptCancellationReason: 'Edit receipt cancellation reason',
            editQuoteCancellationReason: 'Edit quote cancellation reason',
            createReceiptCancellationReason: 'Create receipt cancellation reason',
            createQuoteCancellationReason: 'Create quote cancellation reason',
            updateReceiptCancellationReason: 'Update receipt cancellation reason',
            updateQuoteCancellationReason: 'Update quote cancellation reason',
            receiptCancellationReasonCreatedSuccessfully: 'Receipt cancellation reason created successfully',
            quoteCancellationReasonCreatedSuccessfully: 'Quote cancellation reason created successfully',
            receiptCancellationReasonUpdatedSuccessfully: 'Receipt cancellation reason updated successfully',
            quoteCancellationReasonUpdatedSuccessfully: 'Quote cancellation reason updated successfully',
            receiptCancellationReasonAlreadyExists: 'Receipt cancellation reason already exists',
            quoteCancellationReasonAlreadyExists: 'Quote cancellation reason already exists',
            receiptCancellationReasonNotFound: 'Receipt cancellation reason not found',
            quoteCancellationReasonNotFound: 'Quote cancellation reason not found',
            quoteRejectionReason: 'Quote rejection reason',
            quoteRejectionReasonDetails: 'Quote rejection eason details',
            quoteRejectionReasonManagement: 'Quote rejection reason management',
            quoteRejectionReasons: 'Quote rejection reasons',
            editQuoteRejectionReason: 'Edit quote rejection reason',
            createQuoteRejectionReason: 'Create quote rejection reason',
            updateQuoteRejectionReason: 'Update quote rejection reason',
            quoteRejectionReasonCreatedSuccessfully: 'Quote rejection reason created successfully',
            quoteRejectionReasonUpdatedSuccessfully: 'Quote rejection reason updated successfully',
            quoteRejectionReasonAlreadyExists: 'Quote rejection reason already exists',
            quoteRejectionReasonNotFound: 'Quote rejection reason not found',
            cancel: 'Cancel',
            updateAndSubmitForApproval: 'Update and submit for approval',
            quotationUpdatedSuccessfully: 'Quotation updated successfully',
            quotationCanceledSuccessfully: 'Quotation canceled successfully',
            reasonForCancellation: 'Reason for cancellation',
            cancelQuotation: 'Cancel Quotation',
            relatedDrafts: ' Related Drafts',
            internalReference: 'Internal Reference',
            thisIsJustATemporaryGeneratedReferenceUntilQuotationIsApproved: 'This is just a temporary generated reference until Quotation is approved',
            statusHistory: 'Status History',
            dateSubmittedForApproval: 'Date submitted for approval',
            isThisQuotationSupersedingOtherActiveQuotations: 'Is this quotation superseding other active quotations?',
            dateOfRejection: 'Date of rejection',
            customerRejectedTheQuotation: 'Custome rejected the quotation',
            customerAcceptedTheQuotation: 'customer accepted the quotation',
            hasTheCustomerAcceptedTheQuotation: 'Has the customer accepted the quotation?',
            requestApprovalSentSuccessfully: 'Request approval sent successfuly',
            quotationSentToCustomerSuccessfully: 'Quotation sent to customer successfully',
            sharepointHyperlinkOfTheEmailMessageSubmitted: 'SharePoint hyperlink of the email message submitted',
            dateWhenEmailToPotentialCustomerWasSent: 'Date when email to (potential) customer was sent',
            quotationCannotBeUpdatedIfNotSent: 'Quotation cannot be updated if not sent',
            wasQuotationSubmittedToThePotentialCustomer: 'Was quotation submitted to the (potential) customer ?',
            quotationApprovedSuccessfully: 'Quotation approved successfully',
            quotationSentBackToDraftSuccessfully: 'Quotation sent back to draft successfully',
            sendQuotationToCustomer: 'Send quotation to customer',
            yes: 'Yes',
            no: 'No',
            sendBackToDraft: 'Send back to draft',
            customerAcceptReject: 'Customer Accept/Reject',
            approveSendForChanges: 'Approve/Send for Changes',
            submitForapproval: 'Submit for Approval',
            quotationSentForApproval: 'Quotation sent for approval',
            forRoutieServicesInfoNote: 'For routine services, the quoted amount (excl VAT) should represent the annual fee to be charged.',
            cancelQuote: 'Cancel quote',
            createAndSubmitForApproval: 'Create and submit for approval',
            signatory: 'Signatory',
            submitForApproval: ' Submit for Approval',
            copyQuotation: 'Copy Quotation',
            quotationDate: 'Quotation Date',
            annualAmountExclVat: 'Annual Amount (excl. VAT)',
            approver: 'Approver',
            editSignatory: 'Edit Signatory',
            updateSignatory: 'Update Signatory',
            createSignatory: 'Create Signatory',
            imageData: 'Image Data',
            displayName: 'display Name',
            signatoryManagement: 'Signatory Management',
            signatoryDetails: 'Signatory Details',
            signatories: 'Signatories',
            changes: 'Changes',
            reference: 'Reference',
            audit: 'Audit',
            acronym: 'Acronym',
            frequency: 'Frequency',
            serviceLine: 'Service Line',
            predefinedInvoiceDescription: 'Predefined Invoice Description',
            predefinedQuotationDescription: 'Predefined Quotation Description',
            unabletostartAutomaticTimerStartOrEndTimeOrBothAlreadyBeenInputted: 'Unable to start automatic timer. Start or End time or both already been inputted',
            unabletostartAutomaticTimerTimeSpentAlreadyBeenInputted: 'Unable to start automatic timer. Time spent already been inputted',
            employee: 'Employee',
            employeeName: 'Employee Name',
            startTime: 'Start Time',
            endTime: 'End Time',
            timesheetDate: 'Timesheet Date',
            day: 'Day',
            required: 'Requried',
            totalHoursWorkedThisWeek: 'Total hours worked this week',
            totalHoursWorkedToday: 'Total hours worked today',
            bulkUpdate: 'Bulk Update',
            cancelEdit: 'Cancel Edit',
            stopAutomaticTimer: 'Stop automatic timer',
            saveAndStartAutomaticTimer: 'Save and start automatic timer',
            invoiceAllocation: 'Invoice Allocation',
            destination: 'Destination',
            save: 'Save',
            stage_1_1: 'Stage 1.1',
            stage_1_2: 'Stage 1.2',
            stage_1_3: 'Stage 1.3',
            stage_2_1: 'Stage 2.1',
            stage_2_2: 'Stage 2.2',
            stage_2_3: 'Stage 2.3',
            stage_2_4: 'Stage 2.4',
            stage_2_5: 'Stage 2.5',
            stage_2_6: 'Stage 2.6',
            stage_2_7: 'Stage 2.7',
            stage_3_1: 'Stage 3.1',
            stage_4_1: 'Stage 4.1',
            stage_4_2: 'Stage 4.2',
            stage1: 'Stage 1',
            stage2: 'Stage 2',
            stage3: 'Stage 3',
            stage4: 'Stage 4',
            stage5: 'Stage 5',
            stage6: 'Stage 6',
            stage7: 'Stage 7',
            stage8: 'Stage 8',
            stage9: 'Stage 9',
            stage10: 'Stage 10',
            stage11: 'Stage 11',
            stage12: 'Stage 12',
            stage13: 'Stage 13',
            stage14: 'Stage 14',
            stage14_1: 'Stage 14.1',
            // stage14_2: 'Stage 14.2',
            // stage14_3: 'Stage 14.3',
            // stage14_4: 'Stage 14.4',
            // stage14_5: 'Stage 14.5',
            // stage14_6: 'Stage 14.6',
            stage14_2_14_3_14_4_14_5_14_6: 'Stage 14.2, 14.3, 14.4, 14.5, 14.6',
            stage15: 'Stage 15',
            stage16: 'Stage 16',
            stage17_1: 'Stage 17.1',
            stage17_2: 'Stage 17.2',
            stage17_3: 'Stage 17.3',
            stage17_4: 'Stage 17.4',
            stage18_1: 'Stage 18.1',
            stage18_2: 'Stage 18.2',
            stage18_3: 'Stage 18.3',
            stage19_1: 'Stage 19.1',
            stage19_2: 'Stage 19.2',
            stage20: 'Stage 20',
            stage21_1Final: 'Stage 21.1 Final',
            stage21_2Final: 'Stage 21.2 Final',
            stage21_3Final: 'Stage 21.3 Final',
            remainingAmount: 'Remaining Amount',
            suggestedInvoiceableAmount: 'Suggested Invoiceable Amount',
            basedOnAllocationOfTSEntries: 'based on allocation of T/S entries',
            suggestedAmount: 'Suggested Amount',
            creditAmount: 'Credit Amount',
            paidAllocationAmount: 'Paid Allocation Amount',
            totalAllocation: 'Total Allocation',
            totalManualInputs: 'Total Manual Inputs',
            allocations: 'Allocations',
            allocation: 'Allocation',
            totalAmount: 'Total Amount',
            paidAmount: 'Paid Amount',
            totalInvoicedAmount: 'Total Invoiced Amount',
            totalUnInvoicedAmount: 'Total Uninvoiced Amount',
            totalProfitLoss: 'Total Profit Loss',
            totalEstimatedAmount: 'Total Estimated Amount',
            default: 'Default',
            fromDate: 'From Date',
            toDate: 'To Date',
            invoice: 'Invoice',
            pendingReviews: 'Pending Reviews',
            deleteReceipt: 'Delete Receipt',
            pendingInvoices: 'Pending Invoices',
            pendingTimesheets: 'Pending Timesheets',
            includeInReceipt: 'Include in Receipt',
            markAsPaid: 'Mark as Paid',
            receiptRefNo: 'Receipt Ref No',
            invoiceRefNo: 'Invoice Ref No',
            timesheet: 'Timesheet',
            vatRate: 'VAT Rate',
            invoiceManualInputs: 'Invoice manual inputs',
            invoiceManualInput: 'Invoice manual input',
            note: 'Note',
            breakdownOfCosts: 'Break down of costs',
            updateRate: 'Update Rate',
            approve: 'Approve',
            reject: 'Reject',
            pendingApprovals: 'Pending Approvals',
            pendingBalance: 'Pending Balance',
            creditBalance: 'Credit Balance',
            sendForApproval: 'Send for Approval',
            quotationsPendingApproval: 'Quotations Pending Approval',
            approval: 'Approval',
            approvals: 'Approvals',
            createRate: 'Create Rate',
            rates: 'Rates',
            rate: 'Rate',
            download: 'Download',
            quotationRefNo: 'Quotation Reference Number',
            rationaleForConclusion: 'Rationale for conclusion',
            rationaleMlroConclusion: 'Rationale MLRO conclusion',
            firtherEvidenceInfo: 'Further evidence info',
            legacyId: 'Legacy ID',
            reviewer: 'Reviewer',
            transactionAmount: 'Transaction Amount',
            transactionLocation: 'Tranaction Location',
            transactionDate: 'Transaction Date',
            transactionDescription: 'Transaction Description',
            hasInternalReportInstigatedSuspiciousTransaction: 'Has internal report instigated suspicious transaction?',
            outcomeStr: 'Outcome of suspicious transaction',
            dateStrSubmission: 'Date of suspicious transaction',
            dateMlroReview: 'Date of MLRO review',
            hasMlroAgreementPreparer: 'Has MLRO agreement preparer',
            hasTransactionLegitimate: 'Has transaction legitimate',
            actionsTakenTransactionDetail: 'Actions taken transaction detail',
            reasonTransactionSuspicious: 'Reason transaction suspicious',
            originalTransactionCurrency: 'Original transaction currency',
            foreignAmount: 'Foreign Amount',
            role: 'Role',
            dateOfReport: 'Date of Report',
            hasFurtherEvidence: 'Has Further Evidence',
            termination: 'Termination',
            engagement: 'Engagement',
            antiMoneyLaundering: 'Anti-Money Laundering',
            sourceOfFunds: 'Source of Funds',
            sourceOfWealth: 'Source of Wealth',
            interfaceAndDistribution: 'Interface and Distribution',
            businessActivity: 'Business Activity',
            data: 'Data',
            cddInformation: 'CDD Information',
            basicDetails: 'Basic Details',
            additionalDetails: 'Additional Details',
            contacts: 'Contacts',
            edit: 'Edit',
            customerDueDiligence: 'Customer Due Diligence',
            clientRiskAssessment: 'Client Risk Assessment',
            acceptanceAndContinuance: 'Acceptance And Continuance',
            additionalComments: 'Additional Comments',
            draftData: 'Draft Data',
            quoteAcceptedOn: 'Quote Accepted On',
            recordTags: 'Record Tags',
            recordCreatedSuccessfully: 'Record Created Successfully',
            reasonForRejection: 'Reason For Rejection',
            draft: 'Draft',
            quotations: 'Quotations',
            quotationManagement: 'Quotation Management',
            createQuotation: 'Create Quotation',
            updateQuotation: 'Update Quotation',
            saveAsDraft: 'Save as Draft',
            recordDetails: 'Record Details',
            recordManagement: 'Record Management',
            basicRecordDetails: 'Basic Record Details',
            legalEntityName: 'Legal Entity Name',
            updateRecord: 'Update Record',
            createRecord: 'Create Record',
            updatedDate: 'Updated Date',
            record: 'Record',
            updatedBy: 'Updated By',
            totalDuration: 'Total Duration',
            createdSuccessfully: 'Created Successfully',
            stop: 'Stop',
            months: 'Months',
            years: 'Years',
            start: 'start',
            biller: 'Biller',
            timeSpent: 'Time Spent',
            days: 'Days',
            hours: 'Hours',
            minutes: 'Minutes',
            seconds: 'Seconds',
            entries: 'Entries',
            duration: 'Duration',
            createdBy: 'Created By',
            inputType: 'Input Type',
            endDateTime: 'End Date Time',
            startDateTime: 'Start Date Time',
            workYear: 'Work Year',
            deleted: 'Deleted',
            town: 'Town',
            address: 'Address',
            timesheetManagement: 'Timesheet Management',
            timesheets: 'Timesheets',
            timesheetDetails: 'Timesheet Details',
            editTimesheet: 'Edit Timesheet',
            updateTimesheet: 'Update Timesheet',
            billerManagement: 'Biller Management',
            billers: 'Billers',
            billerDetails: 'Biller Details',
            createBiller: 'Create Biller',
            editBiller: 'Edit Biller',
            updateBiller: 'Update Biller',
            relatedParties: 'Related Parties',
            relatedParty: 'Related Party',
            hasSchemeBenefitsInfoNote: 'i.e. Investor citizenship schemes are often referred to as CIPs (‘citizenship investment programmes’), ‘citizenships for sale’ or ‘golden passports’. They allow foreigners to be naturalised as a citizen of a country in return for an investment, provided certain criteria are fulfilled. Investor citizenship schemes differ from investor residence (‘golden visa’) schemes, which aim to attract investment in exchange for residence rights in the country concerned.',
            influenceDescription: 'Describe how the person exercises significant control or influence over the entity',
            schemeStatus: 'Provide Status of Application',
            schemeDetails: 'Provide Details of Scheme',
            hasSchemeBenefits: 'Benefited from residency schemes, citizenship by investment schemes, or are applicants or prospective applicants for such schemes',
            indirectOwnershipDesc: 'Description of Indirect Ownership',
            natureOfOwnership: 'Nature of Ownership',
            extentOfOwnership: 'Extent of Ownership',
            inputtingAdditionalDetailsOnTheResidencyCitizenship: 'Inputting additional details on the residency/citizenship',
            inputtingTheAdditionalDetailsOnTheNatureOfControlViaOtherMeans: 'Inputting the additional details on the nature of \'Control via other means\'',
            inputtingTheOwnershipDetails: 'Inputting the ownership details',
            inputtingTheBasicDetailsOfTheRelatedParty: 'Inputting the basic details of the related party',
            addRelatedPartiesToThePotentialCustomerCustomer: 'Add related parties to the \'Potential Customer/Customer\'',
            stages: 'Stages',
            ecourts: 'E-Courts',
            assetrecovery: 'Asset Recovery',
            hasAdverseMediaSearchInternetInfoNote: 'Conduct internet searches using different permutations (e.g. adding to the Name and Surname other keywords, e.g +Fraud; +Money laundering; +Court; +Criminal; +Terrorism; etc',
            vats: 'VATs',
            towns: 'Towns',
            tags: 'Tags',
            identityVerificationDocumentType: 'Identity Verification document type',
            idNo: 'ID No.',
            documentNo: 'Document No.',
            documentExpiryDate: 'Document Expiry Date',
            documentCountry: 'Country of Issue of Document',
            proofOfAddressDate: 'Date of proof of address document',
            certificateIncorporation: 'Certificate of Incorporation',
            memorandumAndArticles: 'Memorandum & Articles of Association',
            vatRegCertificate: 'VAT Registration Certificate',
            beneficialOwnersDocument: 'Official document issued by the relevant authorities showing who are the beneficial owners (i.e. shareholders) and directors of the said company',
            certificateGoodStanding: 'Certificate of Good Standing',
            certificateIncumbency: 'Certificate of Incumbency',
            otherDocumentationCollected: 'Other verification documentation collected',
            curriculumVitae: 'Curriculum Vitae with specific emphasis on business experience and employment history (signed and dated)',
            curriculumVitaeDate: 'Date of Curriculum Vitae document',
            groupStructure: 'Group structure signed and dated by the legal representative of the entity',
            hasPepScreeningConducted: 'PEP & Sanction Screening search conducted',
            pepScreeningConductedDate: 'Date of PEP & Sanction Screening Report',
            hasPep1: '- PEP',
            pepInfo: 'PEP Info',
            hasSanctions: '- Sanctions',
            sanctionsInfo: 'Sanctions Info',
            hasAdverseMedia: '- Adverse Media',
            adverseMediaInfo: 'Adverse Media Info',
            hasLawEnforcement: '- Law Enforcement',
            lawEnforcementInfo: 'Law Enforcement Info',
            hasDisqualifiedDirector: '- Disqualified Director',
            disqualifiedDirectorInfo: 'Disqualified Director Info',
            hasInsolvent: '- Insolvent',
            insolventInfo: 'Insolvent Info',
            actionsTakenTrueMatch: 'Summary of action(s) taken in case of true match:',
            hasAdverseMediaSearchInternet: '- Internet ',
            adverseMediaSearchInternetDate: '- Date of Internet search report',
            hasAdverseMediaSearchAssetRecoveryBureau: '- Asset Recovery Bureau',
            adverseMediaSearchAssetRecoveryBureauDate: '- Date of Asset Recovery Bureau search report',
            hasAdverseMediaSearchJudgementCourt: '- E-judgement Court',
            adverseMediaSearchJudgementCourtDate: '- Date of E-judgement Court search report',
            hasAdverseMediaFoundInternet: '- Internet ',
            adverseMediaFoundInternetInfo: 'Internet info',
            hasAdverseMediaFoundAssetRecoveryBureau: '- Asset Recovery Bureau website',
            adverseMediaFoundAssetRecoveryBureauInfo: 'Asset Recovery Bureau website info',
            hasAdverseMediaFoundJudgementCourt: '- E-judgement Court webite',
            adverseMediaFoundJudgementCourtInfo: 'E-judgement Court webite info',
            adverseMediaSummary: 'Summary of action(s) taken in case of adverse media reports found',
            boInformationProof: 'Proof that BO information has been duly registered with a designated BO Register',
            boInformationProofDate: 'Date of proof document',
            otherMeasures: 'Other measures applied - please specify',

            standardCDDMeasures: 'Standard CDD measures',
            adverseMediaResultsFoundFromAnyOfTheFollowingSearches: 'Adverse Media results found from any of the following searches:',
            adverseMediaSearchConductedOnTheseMediaChannels: 'Adverse Media search conducted on these media channels:',
            searchResultedInTrueMatchInOneOfThefollowingAreas: 'Search resulted in true match in one of the following areas?',
            hOtherMeasures: 'H. Other measures',
            gProofOfBOInformation: 'G. Proof of BO Information',
            fSourceOfWealthAndSourceOfFunds: 'F. Source of Wealth and Source of Funds',
            eAdverseMedia: 'E. Adverse Media',
            dPepAndSanctionScreening: 'D. PEP & Sanction Screening',
            cOrganogramGroupStructure: 'C. Organogram/Group Structure',
            bCurriculumVitae: 'B. Curriculum Vitae',
            a4OtherVerificationDocumentsObtained: 'A.4 Other verification documents obtained',
            a3TypeOfVerificationDocumentObtainedLegalEntities: 'A.3 Type of verification document obtained (legal entities)',
            a2TypeOfDocumentObtained: 'A.2. Type of document obtained as \'Proof of Address\' (individuals)',
            a1TypeOfVerificationDocumentObdatinedIndividuals: 'A.1 Type of verification document obtained (individuals)',
            eCompletionAndApproval: 'E. Completion and Approval',
            dOtherMeasures: 'D. Other measures',
            cSourceOfWealthSourceOfFunds: 'C. Source of Wealth/Source of Funds',
            bReferences: 'B. References',
            aAGoodConduct: 'A. Good Conduct',
            hasPoliceConductCertificate: 'Police Conduct Certificate of the individual (or a relevant document issued by a law enforcing authority in the country of origin of the individual and must not be more than 3 months old);',
            policeConductCertificateDate: 'Date of Police Conduct',
            hasBankReference: 'Bank reference (not more than 3 months old)',
            bankReferenceDate: 'Date of Bank reference',
            hasProfessionalReference: 'Professional reference (not more than 3 months old)',
            professionalReferenceDate: 'Date of Professional Reference',
            otherEddMeasures: 'Other EDD measures applied - please specify',
            hasObtainedMlroApproval: 'Obtained MLRO approval (or Board of Directors if the MLRO is the Engagement Partner)',
            enhancedDueDiligenceAdditionalCDDMeasures: 'Enhanced Due Diligence - additional CDD measures:',
            indicatingHowPotentialCustomerWasIntroduced: 'Indicating how (potential) customer was introduced',
            hasRelianceAgreementThirdPartyCdd: 'Reliance arrangement in place with a third party for CDD purposes?',
            hasRelianceAgreementSigned: 'Reliance Agreement signed and in place?',
            indicatingIfRelianceArrangementsAreInPlace: 'Indicating if reliance arrangements are in place',
            indicatingHowWDMIsInterractingWithPotentialCustomer: 'Indicating how WDM is interacting with the (potential) customer',
            hasPoaAuthorized: 'Agent/Representative is duly authorised in writing to act for and on behalf of the customer (e.g. M&A, power of attorney, Resolution, Letter of Autorisation, LOE etc);  ',
            hasPoaDocumentCollected: 'Is power of attorney document collected?',
            hasReasonableRationale: 'Is there a reasonable rationale behind this arrangement (especially if the corporate customer is not being represented by its own directors who are vested with its legal representation)',
            reasonableRationaleInfo: 'Reasonable rationale info',
            dealDirectlywith: 'Deal directly with (potential) customer or through an intermediary/representative/agent?',
            introducedByInfoNote: 'i.e.  Agent means a person or entity who acts on the customer\'s behalf (be it the corporate client itself or the prospective shareholder, partner or BO) and has the authority to bind the customer with his / her actions.A person who executes transactions for and on behalf of a customer(such as when duly appointed to act as signatory on the customer\'s bank account) or who is duly authorised to sign contracts / agreements binding the customer(such as a company director) are considered to be Agents.A regulated intermediary refers to an individual / entity that undertakes a relevant activity or a relevant financial business and who / which is regulated by the Malta Financial Services Authority or regulated in another EU Member State or reputable jurisdiction.',
            indicatingOnboadingOptions: 'Indicating the onboarding options',
            onboardedOnAFaceToFaceOrANonFaceToFaceBasis: '(To be) onboarded on a face-to-face or a non face-to-face basis?',
            saleMaturityOfInvestmentsInfoNote: '• Description of shares/units/ deposits\n• Time period held\n• Total sale/liquidation amount\n• Date funds received',
            maturitySurrenderOfLifePolicyInfoNote: '• Amount received \n• Policy provider\n• Policy number/reference\n• Date of surrender',
            lotteryBettingCasinoWinInfoNote: '• Amount won\n• Date received\n• Name of relevant organisation (Lottery headquarters/betting shop or website / casino)',
            dividentPaymentInfoNote: '• Date of receipt of dividend\n• Total amount received\n• Name of company paying dividend\n• Length of time the shares have been held in the company',
            fixedDepositSavingsInfoNote: '• Name of institution where savings account is held\n• Date the account was establishedzn• Details of how the savings were acquired should be provided',
            giftDonationInfoNote: '• Date received\n• Total amount\n• Relationship to customer\n• Certified identification documents for donor\n• Donor’s source of wealth',
            divorceSettlementInfoNote: '• Date received\n• Total amount received\n• Name of divorced partner',
            inheritanceInfoNote: '• Name of deceased\n• Date of death\n• Relationship to customer\n• Date received\n• Total amount\n• Notary’s details',
            companySaleInfoNote: '• Name and nature of the company\n• Date of sale\n• Total sale amount\n• Client’s share',
            rentalOfPropertyInfoNote: '• Address of property rented\n• Total rental amount',
            saleOfPropertyAssetsInfoNote: '• Address of property sold\n• Date of sale\n• Total sale amount',
            businessIncomeCompanyProfitsInfoNote: '• Company name and address\n• Nature of company\n• Amount of annual profit',
            incomeSavingsFromSalaryInfoNote: '• Salary per annum \n• Employer’s name and address\n• Nature of business',
            indicateTheEconomicActivitiesThatGenerated: 'Indicate the economic activities that generated the total net worth:',
            egNaturalPersonOfferedIncomeTaxCompliance: 'e.g. Natural person offered income tax compliance, thus not a relevant activity; or Corporate Services is financing the activity of the Customer through private loans',
            indicateTheEconomicActivitiesThatGenerateTheTotalNetWorth: 'Indicate the economic activities that generated the total net worth:',
            naAsNewlyEstablishedEntity: 'N/A as newly established entity',
            naAsNewlyEstablishedEntityInfo: 'N/A as newly established entity info',
            incomeSavingsFromSalary: 'Income/savings from salary (basic and/or bonus)',
            incomeSavingsFromSalaryInfo: 'Income/savings from salary (basic and/or bonus) info',
            businessIncomeCompanyProfits: 'Business Income/Company profits',
            businessIncomeCompanyProfitsInfo: 'Business Income/Company profits info',
            saleOfPropertyAssets: 'Sale of property/assets',
            saleOfPropertyAssetsInfo: 'Sale of property/assets info',
            rentalOfProperty: 'Rental of property',
            rentalOfPropertyInfo: 'Rental of property Info',
            companySale: 'Company sale',
            companySaleInfo: 'Company sale info',
            inheritance: 'Inheritance',
            inheritanceInfo: 'Inheritance info',
            divorceSettlement: 'Divorce settlement',
            divorceSettlementInfo: 'Divorce settlement info',
            giftDonation: 'Gift/Donation',
            giftDonationInfo: 'Gift/Donation info',
            fixedDepositSavings: 'Fixed deposit – savings',
            fixedDepositSavingsInfo: 'Fixed deposit – savings info',
            dividendPayment: 'Dividend payment',
            dividendPaymentInfo: 'Dividend payment info',
            lotteryBettingCasinoWin: 'Lottery/betting/casino win',
            lotteryBettingCasinoWinInfo: 'Lottery/betting/casino win info',
            maturitySurrenderOfLifePolicy: 'Maturity or surrender of life policy',
            maturitySurrenderOfLifePolicyInfo: 'Maturity or surrender of life policy info',
            saleMaturityOfInvestments: 'Sale/maturity of investments',
            saleMaturityOfInvestmentsInfo: 'Sale/maturity of investments info',
            otherPleaseSpecify: 'Other',
            otherPleaseSpecifyInfo: 'Other Specify Info',
            sourceOfWealthInformationNeedtoBecollatedRationale: 'Rationale on collated Source of Wealth information',
            sourceOfWealthInformationNeedtoBecollated: 'Source of Wealth information need to be collated?',
            indicateIfHighNetworthIndividual: 'Indicate if High Net Worth Individual',
            estimatedTotalNetWorth: 'Estimated total net-worth',
            inputtingDetailsOnTheEconomicActivitiesThatGeneratedTheTotalNetWorth: 'Inputting details on the economic activities that generated the total net worth',
            inputtingDetailsOnTheNetWorth: 'Inputting details on the net worth',
            includingInformationOnTheSourceOfWealth: 'Including information on the source of wealth',
            dateOfTermination: 'Date of termination of business relationship',
            reasonForTerminationInfo: 'Reason for termination Info',
            dateOfSignatureEngagement: 'Date of Signature of Letter of Engagement',
            terminationOfClient: 'Termination of client',
            issueOfLetterOfEngagementFromSystem: 'Issue of letter of engagement from system',
            systemConfigurations: 'System Configurations',
            egInstitutionProvidingtheLoan: 'e.g. institution providing the loan, amount of loan, etc',
            egNameOfPersonProvidingTheLoan: 'e.g.name of person providing the loan, amount of loan, relationship of person providing the loan with the customer, etc',
            egNameOfShareHolderProvidingtheEquity: 'e.g. Name of shareholder providing the equity, amount, date, etc',
            egCompanyRevenueSalaryPensionEtc: 'e.g. Company Revenue, Salary, Pension, etc',
            areTheSourceOfFundsUsedRelative: 'Are the source of funds used relative to finance the business activity/transaction derived from:',
            hasSourceOfFundsCollated: 'Source of Funds information need to be collated?',
            sourceOfFundsCollatedInfo: 'Rationale for your answer',
            hasEconomicActivities: '- The economic activities conducted in the normal course of the business',
            economicActivitiesInfo: 'Economic Activities Info',
            hasNewEquity: '- New equity',
            newEquityInfo: 'New Equity Info',
            hasPrivateLoan: '- Private loan',
            privateLoanInfo: 'Private Loan Info',
            hasBankLoanOrCreditFacility: '- Bank loan or credit facility',
            bankLoanOrCreditFacilityInfo: 'Bank loan or credit facility Info',
            hasOther: '- Other',
            otherInfo: '(please specify other)',
            sourceOfFundsOriginatingFrom: 'Country from where the funds are originating',

            includingInformationOnTheSourceOfFunds: 'Including Information on the Source of Funds',

            clientAcceptanceandContinuance: 'Client acceptance & continuance',
            onboarded: 'Client Onboarded',
            dateOfCommencement: 'Date of commencement of business relationship',
            reasonForNotOnboardingInfo: 'Reason for not onboarding Info',

            hasPep: 'In the last 18 months held a position of a PEP, or are related family members or close associates of a PEP?',
            relationshipwithPep: 'Relationship with PEP',
            pepPublicPosition: 'Public position held by PEP',
            pepFirstName: 'First name of PEP',
            pepLastName: 'Last name of PEP',
            pepPublicEntity: 'Name of public entity',
            pepStartDate: 'Date of appointment as PEP',
            updatedSuccessfully: 'Updated Successfully',
            updateFailed: 'Update Failed',
            fieldIsRequired: 'Field is required',
            indicatingIfPoliticallyExposed: 'Indicating if politically exposed',
            beenSubjectToASupervisory: 'Been subject to a supervisory or regulatory action in the past?',
            whatWasTheNatureOfTheBreach: 'What was the nature of the breach that led to the taking of that action. ',
            haveTheRegulatoryIssuesBeenResolved: 'Have the regulatory issues been resolved',
            whatActionsWereTakenToAddress: 'What actions were taken to address the issues highlighted by the regulator or supervisor?',

            convictedOfaCriminalOffence: 'Convicted of a criminal offence that could have potentially generated illicit proceeds?',
            convictedOfaCriminalOffenceInfo: 'Details of criminal offence',

            dealWithCountriesThatAreSubject: 'Deal with countries that are subject to the sanctions of the United Nations, European Union or United States of America?',
            dealWithCountriesThatAreSubjectInfo: 'Details of dealings with countries',

            everBeenSubjectToAnyFreezingOrder: 'Ever been subject to any freezing orders or Court Enforcement Prohibition on the disposal of its assets and/or any financial sanctions or any proceedings in relation to any offence involving money-laundering and/or funding of terrorism?',
            everBeenSubjectToAnyFreezingOrderInfo: 'Details of freezing orders or Court Enforcement Prohibition etc',
            indicatingIfWasEverSubjectToAnySanctionsAndOrEnforcements: 'Indicating if was ever subject to any sanctions and/or enforcements',
            euTaxJurisdictions: ' EU tax jurisdictions',
            doesTheOwnershipStructureInclude: 'Does the ownership structure include:',
            offshoreVehiclesTrusts: '- offshore vehicles, trusts or other legal arrangement including bearer shares, and nominee shareholdings',
            foundationsCharities: '- foundations, charities and not-for-profit entities',
            aShellCompany: '- a shell company (i.e. companies with no independent operations, significant assets, ongoing business activities or employees)',
            entitiesIncorporated: '- entities incorporated in jurisdiction listed in the EU list of Non-Cooperative Jurisdictions for tax purposes',
            indicatingTheComplexityOfTheOwnershipStructure: 'Indicating the complexity of the ownership structure of the \'Potential Customer/Customer\'',
            interfaceDistributionChannels: 'Interface/Distribution Channels',
            pepAndSanctions: 'PEP and Sanctions',
            ownershipAndControl: 'Ownership and Control',
            cddInfo: 'Customer Due Diligence Info',
            sow: 'Source of Wealth',
            sof: 'Source Of Funds',
            clientAcceptanceAndContinuance: 'Client Acceptance & Continuance',
            addContactPerson: 'Add contact person',
            bankFacility: 'Bank Facility',
            addauthorizedBankSignatory: 'Add authorized bank signatory',
            indicationOfJurisdictionWhereActivitiesInfo: 'Detail of jurisdications where activities of the customer are taking place',
            indicationOfJurisdictionWhereActivitiesIInfoNote: '(i) in the case of business activities ,\n- indicate where the operations and the trading activities are happening;\n- indicate where the majority of the customers and suppliers are located',
            indicationOfJurisdictionWhereActivitiesIIInfoNote: '(ii) in the case of occupation/employment\n- indicate where the activity happens',
            indicationOfTheExpectedTotalIncomeGeneratedIInfoNote: '(i) in the case of business activities, indicate the expected total income generated from the business activities',
            indicationOfTheExpectedTotalIncomeGeneratedIIInfoNote: '(ii) in case of occupation/employment/pension/rental income, etc indicate the expected annual income earned',
            descriptionOfThePrincipalBusinessActivitiesIInfoNote: '(i) in case of business activities, include a detailed description of the products/services offered, number of employees engaged, etc',
            descriptionOfThePrincipalBusinessActivitiesIIInfoNote: '(ii) in case of occupation/employment include employer\'s name and nature of business',
            indicationOfJurisdictionWhereActivities: '(c) Indication of the jurisdications where activities of the customer are taking place',
            indicationOfTheExpectedTotalIncomeGenerated: '(b) Indication of the expected total income generated by the customer and main expenditure activities (if any)',
            descriptionOfThePrincipalBusinessActivities: '(a) Description of the principal business activities, (or occupation, employment) etc, which generate income',
            inAccordanceWithSL36512InfoNote: 'In accordance with SL 365.12, dual-use items refers to any used or unused items, including software and technology, which can be used for both civil and military purposes, and including all goods which can be used for both non-explosive uses and for assisting in any way in the manufacture of nuclear weapons or other nuclear explosive devices. Dual-use items are to be considered as those designated by the Company\'s policies and procedures or through the SL 365.12.',
            dualUseItemsInfo: 'Dual Use items information',
            cashIntensiveBusinessInfo: 'Cash intensive business information',
            operateIndualUseItems: 'Operate in dual use items?',
            customerOperatesACashIntensiveBusiness: 'Customer operates a cash intensive business?',
            operateInAnyOfTheseHighRiskIndustries: 'Operate in any of these high-risk industries',

            authorizedBankSignatories: 'Authorized Bank Signatories:',
            authorizedBankSignatory: 'Authorized Bank Signatory',
            addAuthorizedBankSignatory: 'Add Authorized Bank Signatory',
            removeAuthorizedBankSignatory: 'Remove Authorized Bank Signatory',
            authorizedBankRepresentatives: 'Authorized Bank Representatives',
            authorizedBankRepresentative: 'Authorized Bank Representative',
            addAuthorizedBankRepresentative: 'Add Authorized Bank Representative',
            removeAuthorizedBankRepresentative: 'Remove Authorized Bank Representative',
            removeThisBankArrangement: 'Remove this Bank Arrangement',
            removeThisBankFacility: 'Remove this Bank Facility',
            addBankArrangement: 'Add Bank Arrangement',
            addBankFacility: 'Add Bank Facility',
            bankFacilities: 'Bank Facilities',
            bankFacilitiesHeld: 'Bank Facilities Held',
            bankFacilitiesHeldInfo: 'Bank Facilities Held Info',
            includingDetailsOnTheBankingFiduciaryArrangements: 'Including details on the banking/fiduciary arrangements',
            editIncludingDetailsOnTheBankingFiduciaryArrangements: 'Edit Including details on the banking/fiduciary arrangements',
            bankJurisdiction: 'Bank Jurisdiction',
            bankName: 'Bank Name',
            bankArrangements: 'Bank Arrangements',
            customerHasBankingArrangements: 'Customer has any banking arrangements?',
            amountOfFundsHeldAtYearEnd: 'Amount of funds held at year-end',
            yearEnd: 'Year-end',
            infoAboutFundsHeld: 'Info about funds held',
            areAnyFundsHeldOnTrust: 'Are any funds held on trust, or in another fiduciary capacity in WDM\'s client account?',
            virtualFinancialAssetShallBeInfoNote: "i.e. \"Virtual financial asset\" shall be construed to have the same meaning given under the Virtual Financial Assets Act, Chapter 590 of the Laws of Malta: \"virtual financial asset\" or \"VFA\" means any form of digital medium recordation that is used as a digital medium of exchange, unit of account, or store of value and that is not - (a) electronic money; (b) a financial instrument; or (c) a virtual token",
            raisedCapitalThroughInitialCoinOfferingsInfoNote: "Raised capital through Initial Coin Offerings (ICOs), Securitised Coin Offerings (SCOs) and/or crowdfunding?",
            accountOfCapitalRaisedInfoNote: "Amount of capital raised",
            holdsVirtualFinancialAssetsInfoNote: "Holds virtual financial assets",
            carriesOutTransactionsInfoNote: "Carries out transactions (receipts or payments) through the use of virtual financial assets",
            includingDetailsOnAnyVirtualAssetsHeldUsed: "Including details on any virtual assets held/used",
            editIncludingDetailsOnAnyVirtualAssetsHeldUsed: "Edit Including details on any virtual assets held/used",
            filingOfTaxReturnWithICTU: 'Filing of tax return with ICTU',
            editFilingOfTaxReturnWithICTU: 'Edit Filing of tax return with ICTU',
            filedTheTaxReturnWithTheInternationalComplianceTaxUnit: 'Filed the tax return with the International Compliance Tax Unit?',
            indicatingThePrincipalPlaceOfBusinessInfoNote: 'i.e. the place where key management and commercial decisions that are necessary for the conduct of the business of an entity as a whole are, in substance, made.',
            addInvestment: 'Add Investment',
            editIndicatingThePrincipalPlaceOfBusiness: 'Edit Indicating the principal place of business',
            editAdditionalDetailsOnTheTradingHoldingActivities: 'Edit Additional Details on the Trading/Holding Activities',
            additionalDetailsOnTheBusinessActivity: "Additional details on the business activity",
            indicatingThePrincipalPlaceOfBusiness: "Indicating the principal place of business",
            isAddressDifferentFromTheRegisteredAddress: "Is address different from the Registered Address?",
            typeOfInvestmentsHeld: 'Type of investments held',
            additionalInfoOnInvestmentHeld: 'Additional Info on the Investment held',
            jurisdictionWhereInvestmentIsHeld: 'Jurisdiction where investment is held',
            investments: 'Investments',
            tradingActivities: 'Undertake any trading activities?',
            transferredFromAnotherJurisdiction: "Redomiciled or transferred the economic activity from another jurisidiction?",
            transferredFromJurisdiction: "Transferred from jurisdiction",
            transferredToAnotherJurisdiction: "Redomiciled or transferred the economic activity to another jurisidiction?",
            transferredToJurisdiction: "Transferred to jurisdiction",
            tradingOrHolding: "Act as Trading or Holding Company?",
            undertakeAnyTradingActivities: 'Undertake any trading activities?',
            addressLine: 'Address Line',
            type: 'Type',
            editAdditionalDetailsForPublicListedEntities: 'Edit Additional Details for \'Public Listed Entities\'',
            editAdditionalDetailsForRegulatedLicensedEntities: 'Edit Additional Details for \'Regulated/Licensed Entities\'',
            editDetailsOfContactPerson: 'Edit Details of Contact Person',
            editBasicPotentialCustomerDetails: 'Edit Basic \'Potential Customer\' Details',
            additionalClientDetails: 'Additional Client Details',
            contactPerson: 'Contact Person',
            sameAsPotentialCustomer: 'Same as Potential Customer',
            correspondenceAddress: 'Correspondence Address',
            countryOfCitizenship: 'Country of citizenship',
            nationality: 'Nationality',
            basicPotentialCustomerDetails: 'Basic \'Potential Customer\' details',
            accountManager: 'Account Manager',
            quotationAcceptedSuccessfully: 'Quotation accepted successfully',
            editQuotationAcceptance: 'Edit quotation acceptance',
            quotationRejectedSuccessfully: 'Quotation rejected successfully',
            stageLog: 'Stage Log',
            editQuotation: 'Edit Quotation',
            quotationCreatedSuccessfully: 'Quotation created successfully',
            quotation: 'Quotation',
            quotationDetails: 'Quotation Details',
            excludingVat: 'Excluding VAT',
            refeeredByOrKnownAs: 'e.g. referred by; or known as; etc',
            import: 'Import',
            tag: 'Tag',
            customer: 'Customer',
            potentialCustomer: 'Potential Customer',
            quotationAcceptedOn: 'Quotation Accepted On',
            filledByEntityDuringKyc: 'Filled by client during KYC',
            quotationAcceptanceAccountManager: 'Quotation Acceptance Account Manager',
            potentialCustomerDetailsKyc: 'Potential Customer Details KYC',
            issueQuotationKyc: 'Issue quotation KYC',
            quotedAmountExcludingVat: 'Quoted amount (excl VAT)',
            refNo: 'Reference Number',
            clientOnboarding: 'Client Onboarding',

            tagManagement: 'Tag Management',
            vatManagement: 'VAT Management',
            titleManagement: 'Title Management',
            serviceManagement: 'Service Management',
            legalEntityTypeManagement: 'Legal Entity Type Management',
            placeOfBirthManagement: 'Place of Birth Management',
            genderManagement: 'Gender Management',
            currencyManagement: 'Currency Management',
            industryManagement: 'Industry Management',
            sectorManagement: 'Sector Management',
            licenseCategoryManagement: 'License Category Management',
            nameOfRegulatorManagement: 'Name of Regulator Management',
            jurisdictionOfRegulatorManagement: 'Jurisdiction of Regulator Management',
            typeOfListingManagement: 'Type of Listing Management',
            investmentTypeManagement: 'Investment Type Management',
            highRiskIndustryManagement: 'High Risk Industry Management',
            relationshipManagement: 'Relationship Management',
            layerOfOwnershipManagement: 'Layer of Ownership Management',
            detailsOfSchemeManagement: 'Management of Scheme Management',
            statusOfApplicationManagement: 'Status of Application Management',
            pepRelationshipManagement: 'Pep Relationship Management',
            introducedByManagement: 'Introduced By Management',
            dealingDirectlywithManagement: 'Dealing Directly With Management',
            identityDocumentTypeManagement: 'Identity Document Type Management',
            proofOfAddressManagement: 'Proof of Address Management',
            estimatedNetWorthManagement: 'Estimated Net Worth Management',
            reasonForNotOnboardingManagement: 'Reason For Not Onboarding Management',
            reasonForTerminationManagement: 'Reason For Termination Management',
            timesheetTypeManagement: 'Timesheet Type Management',
            townManagement: 'Town Management',

            createTag: 'Create Tag',
            createVat: 'Create VAT',
            createTitle: 'Create Title',
            createService: 'Create Service',
            createLegalEntityType: 'Create Legal Entity Type',
            createPlaceOfBirth: 'Create Place of Birth',
            createGender: 'Create Gender',
            createCurrency: 'Create Currency',
            createIndustry: 'Create Industry',
            createSector: 'Create Sector',
            createLicenseCategory: 'Create License Category',
            createNameOfRegulator: 'Create Name of Regulator',
            createJurisdictionOfRegulator: 'Create Jurisdiction of Regulator',
            createTypeOfListing: 'Create Type of Listing',
            createInvestmentType: 'Create Investment Type',
            createHighRiskIndustry: 'Create High Risk Industry',
            createRelationship: 'Create Relationship',
            createLayerOfOwnership: 'Create Layer of Ownership',
            createDetailsOfScheme: 'Create Details of Scheme',
            createStatusOfApplication: 'Create Status of Application',
            createPepRelationship: 'Create Pep Relationship',
            createIntroducedBy: 'Create Introduced By',
            createDealingDirectlywith: 'Create Dealing Directly With',
            createIdentityDocumentType: 'Create Identity Document Type',
            createProofOfAddress: 'Create Proof of Address',
            createEstimatedNetWorth: 'Create Estimated Net Worth',
            createReasonForNotOnboarding: 'Create Reason For Not Onboarding',
            createReasonForTermination: 'Create Reason For Termination',
            createTimesheetType: 'Create Timesheet Type',
            createTown: 'Create Town',

            updateTag: 'Update Tag',
            updateVat: 'Update VAT',
            updateTitle: 'Update Title',
            updateService: 'Update Service',
            updateLegalEntityType: 'Update Legal Entity Type',
            updatePlaceOfBirth: 'Update Place of Birth',
            updateGender: 'Update Gender',
            updateCurrency: 'Update Currency',
            updateIndustry: 'Update Industry',
            updateSector: 'Update Sector',
            updateLicenseCategory: 'Update License Category',
            updateNameOfRegulator: 'Update Name of Regulator',
            updateJurisdictionOfRegulator: 'Update Jurisdiction of Regulator',
            updateTypeOfListing: 'Update Type of Listing',
            updateInvestmentType: 'Update Investment Type',
            updateHighRiskIndustry: 'Update High Risk Industry',
            updateRelationship: 'Update Relationship',
            updateLayerOfOwnership: 'Update Layer of Ownership',
            updateDetailsOfScheme: 'Update Details of Scheme',
            updateStatusOfApplication: 'Update Status of Application',
            updatePepRelationship: 'Update Pep Relationship',
            updateIntroducedBy: 'Update Introduced By',
            updateDealingDirectlywith: 'Update Dealing Directly With',
            updateIdentityDocumentType: 'Update Identity Document Type',
            updateProofOfAddress: 'Update Proof of Address',
            updateEstimatedNetWorth: 'Update Estimated Net Worth',
            updateReasonForNotOnboarding: 'Update Reason For Not Onboarding',
            updateReasonForTermination: 'Update Reason For Termination',
            updateTimesheetType: 'Update Timesheet Type',
            updateTown: 'Update Town',

            tagDetails: 'Tag Details',
            vatDetails: 'VAT Details',
            titleDetails: 'Title Details',
            serviceDetails: 'Service Details',
            quotedService: 'Quoted Service',
            legalEntityTypeDetails: 'Legal Entity Type Details',
            placeOfBirthDetails: 'Place of Birth Details',
            genderDetails: 'Gender Details',
            currencyDetails: 'Currency Details',
            industryDetails: 'Industry Details',
            sectorDetails: 'Sector Details',
            licenseCategoryDetails: 'License Category Details',
            nameOfRegulatorDetails: 'Name of Regulator Details',
            jurisdictionOfRegulatorDetails: 'Jurisdiction of Regulator Details',
            typeOfListingDetails: 'Type of Listing Details',
            investmentTypeDetails: 'Investment Type Details',
            highRiskIndustryDetails: 'High Risk Industry Details',
            relationshipDetails: 'Relationship Details',
            layerOfOwnershipDetails: 'Layer of Ownership Details',
            detailsOfSchemeDetails: 'Details of Scheme Details',
            statusOfApplicationDetails: 'Status of Application Details',
            pepRelationshipDetails: 'Pep Relationship Details',
            introducedByDetails: 'Introduced By Details',
            dealingDirectlywithDetails: 'Dealing Directly With Details',
            identityDocumentTypeDetails: 'Identity Document Type Details',
            proofOfAddressDetails: 'Proof of Address Details',
            estimatedNetWorthDetails: 'Estimated Net Worth Details',
            reasonForNotOnboardingDetails: 'Reason For Not Onboarding Details',
            reasonForTerminationDetails: 'Reason For Termination Details',
            timesheetTypeDetails: 'Timesheet Type Details',
            townDetails: 'Town Details',

            editTown: 'Edit Town',
            editVat: 'Edit VAT',
            editTag: 'Edit Tag',
            editTitle: 'Edit Title',
            editService: 'Edit Service',
            editLegalEntityType: 'Edit Legal Entity Type',
            editPlaceOfBirth: 'Edit Place of Birth',
            editGender: 'Edit Gender',
            editCurrency: 'Edit Currency',
            editIndustry: 'Edit Industry',
            editSector: 'Edit Sector',
            editLicenseCategory: 'Edit License Category',
            editNameOfRegulator: 'Edit Name of Regulator',
            editJurisdictionOfRegulator: 'Edit Jurisdiction of Regulator',
            editTypeOfListing: 'Edit Type of Listing',
            editInvestmentType: 'Edit Investment Type',
            editHighRiskIndustry: 'Edit High Risk Industry',
            editRelationship: 'Edit Relationship',
            editLayerOfOwnership: 'Edit Layer of Ownership',
            editDetailsOfScheme: 'Edit Details of Scheme',
            editStatusOfApplication: 'Edit Status of Application',
            editPepRelationship: 'Edit Pep Relationship',
            editIntroducedBy: 'Edit Introduced By',
            editDealingDirectlywith: 'Edit Dealing Directly With',
            editIdentityDocumentType: 'Edit Identity Document Type',
            editProofOfAddress: 'Edit Proof of Address',
            editEstimatedNetWorth: 'Edit Estimated Net Worth',
            editReasonForNotOnboarding: 'Edit Reason For Not Onboarding',
            editReasonForTermination: 'Edit Reason For Termination',

            vat: 'VAT',
            timesheetTypes: 'Timesheet Types',
            titles: 'Titles',
            services: 'Services',
            legalEntityTypes: 'Legal Entity Types',
            placeOfBirths: 'Place of Births',
            genders: 'Genders',
            currencies: 'Currencies',
            industries: 'Industries',
            sectors: 'Sectors',
            licenseCategories: 'License Categories',
            nameOfRegulators: 'Name of Regulators',
            jurisdictionOfRegulators: 'Jurisdiction of Regulators',
            typeOfListings: 'Type of Listings',
            investmentTypes: 'Investment Types',
            highRiskIndustries: 'High Risk Industries',
            relationships: 'Relationships',
            layerOfOwnerships: 'Layer of Ownerships',
            detailsOfSchemes: 'Details of Schemes',
            statusesOfApplication: 'Statuses of Application',
            pepRelationships: 'Pep Relationships',
            introducedBys: 'Introduced Bys',
            dealingDirectlyWith: 'Dealing Directly With',
            identityDocumentTypes: 'Identity Document Types',
            proofOfAddresses: 'Proof of Addresses',
            estimatedNetWorths: 'Estimated Net Worths',
            reasonsForNotOnboarding: 'Reasons For Not Onboarding',
            reasonsForTermination: 'Reasons For Termination',
            accountManagerCreatedSuccessfully: 'Account Manager created successfully',

            tagCreatedSuccessfully: 'Tag created successfully',
            vatCreatedSuccessfully: 'VAT created successfully',
            titleCreatedSuccessfully: 'Title created successfully',
            serviceCreatedSuccessfully: 'Service created successfully',
            legalEntityTypeCreatedSuccessfully: 'Legal Entity Type created successfully',
            placeOfBirthCreatedSuccessfully: 'Place of Birth created successfully',
            genderCreatedSuccessfully: 'Gender created successfully',
            currencyCreatedSuccessfully: 'Currency created successfully',
            industryCreatedSuccessfully: 'Industry created successfully',
            sectorCreatedSuccessfully: 'Sector created successfully',
            licenseCategoryCreatedSuccessfully: 'License Category created successfully',
            nameOfRegulatorCreatedSuccessfully: 'Name of Regulator created successfully',
            jurisdictionOfRegulatorCreatedSuccessfully: 'Jurisdiction of Regulator created successfully',
            typeOfListingCreatedSuccessfully: 'Type of Listing created successfully',
            investmentTypeCreatedSuccessfully: 'Investment Type created successfully',
            highRiskIndustryCreatedSuccessfully: 'High Risk Industry created successfully',
            relationshipCreatedSuccessfully: 'Relationship created successfully',
            layerOfOwnershipCreatedSuccessfully: 'Layer of Ownership created successfully',
            detailsOfSchemeCreatedSuccessfully: 'created successfully of Scheme created successfully',
            statusOfApplicationCreatedSuccessfully: 'Status of Application created successfully',
            pepRelationshipCreatedSuccessfully: 'Pep Relationship created successfully',
            introducedByCreatedSuccessfully: 'Introduced By created successfully',
            dealingDirectlywithCreatedSuccessfully: 'Dealing Directly With created successfully',
            identityDocumentTypeCreatedSuccessfully: 'Identity Document Type created successfully',
            proofOfAddressCreatedSuccessfully: 'Proof of Address created successfully',
            estimatedNetWorthCreatedSuccessfully: 'Estimated Net Worth created successfully',
            reasonForNotOnboardingCreatedSuccessfully: 'Reason For Not Onboarding created successfully',
            reasonForTerminationCreatedSuccessfully: 'Reason For Termination created successfully',
            timesheetTypeCreatedSuccessfully: 'Timesheet Type created successfully',
            townCreatedSuccessfully: 'Town created successfully',


            tagUpdatedSuccessfully: 'Tag updated successfully',
            vatUpdatedSuccessfully: 'VAT updated successfully',
            titleUpdatedSuccessfully: 'Title updated successfully',
            serviceUpdatedSuccessfully: 'Service updated successfully',
            legalEntityTypeUpdatedSuccessfully: 'Legal Entity Type updated successfully',
            placeOfBirthUpdatedSuccessfully: 'Place of Birth updated successfully',
            genderUpdatedSuccessfully: 'Gender updated successfully',
            currencyUpdatedSuccessfully: 'Currency updated successfully',
            industryUpdatedSuccessfully: 'Industry updated successfully',
            sectorUpdatedSuccessfully: 'Sector updated successfully',
            licenseCategoryUpdatedSuccessfully: 'License Category updated successfully',
            nameOfRegulatorUpdatedSuccessfully: 'Name of Regulator updated successfully',
            jurisdictionOfRegulatorUpdatedSuccessfully: 'Jurisdiction of Regulator updated successfully',
            typeOfListingUpdatedSuccessfully: 'Type of Listing updated successfully',
            investmentTypeUpdatedSuccessfully: 'Investment Type updated successfully',
            highRiskIndustryUpdatedSuccessfully: 'High Risk Industry updated successfully',
            relationshipUpdatedSuccessfully: 'Relationship updated successfully',
            layerOfOwnershipUpdatedSuccessfully: 'Layer of Ownership updated successfully',
            detailsOfSchemeUpdatedSuccessfully: 'updated successfully of Scheme updated successfully',
            statusOfApplicationUpdatedSuccessfully: 'Status of Application updated successfully',
            pepRelationshipUpdatedSuccessfully: 'Pep Relationship updated successfully',
            introducedByUpdatedSuccessfully: 'Introduced By updated successfully',
            dealingDirectlywithUpdatedSuccessfully: 'Dealing Directly With updated successfully',
            identityDocumentTypeUpdatedSuccessfully: 'Identity Document Type updated successfully',
            proofOfAddressUpdatedSuccessfully: 'Proof of Address updated successfully',
            estimatedNetWorthUpdatedSuccessfully: 'Estimated Net Worth updated successfully',
            reasonForNotOnboardingUpdatedSuccessfully: 'Reason For Not Onboarding updated successfully',
            reasonForTerminationUpdatedSuccessfully: 'Reason For Termination updated successfully',
            timesheetTypeUpdatedSuccessfully: 'Timesheet Type updated successfully',
            townUpdatedSuccessfully: 'Town updated successfully',

            title: 'Title',
            service: 'Service',
            legalEntityType: 'Legal Entity Type',
            placeOfBirth: 'Place of Birth',
            gender: 'Gender',
            currency: 'Currency',
            industry: 'Industry',
            sector: 'Sector',
            licenseCategory: 'License Category',
            nameOfRegulator: 'Name of Regulator',
            jurisdictionOfRegulator: 'Jurisdiction of Regulator',
            typeOfListing: 'Type of Listing',
            investmentType: 'Investment Type',
            highRiskIndustry: 'High Risk Industry',
            relationship: 'Relationship',
            layerOfOwnership: 'Layer of Ownership',
            detailsOfScheme: 'Details of Scheme',
            statusOfApplication: 'Status of Application',
            pepRelationship: 'Pep Relationship',
            introducedBy: 'Introduced By',
            dealingDirectlywith: 'Dealing Directly With',
            identityDocumentType: 'Identity Document Type',
            proofOfAddress: 'Proof of Address',
            estimatedNetWorth: 'Estimated Net Worth',
            reasonForNotOnboarding: 'Reason For Not Onboarding',
            reasonForTermination: 'Reason For Termination',
            timesheetType: 'Timesheet Type',


            value: 'Value',
            editReceipt: 'Edit Receipt',
            receiptDetails: 'Receipt Details',
            receiptManagement: 'Receipt Management',
            updateReceipt: 'Update Receipt',
            createReceipt: 'Create Receipt',
            editInvoice: 'Edit Invoice',
            invoiceDetails: 'Invoice Details',
            invoiceManagement: 'Invoice Management',
            updateInvoice: 'Update Invoice',
            createInvoice: 'Create Invoice',
            invoices: 'Invoices',
            kyc: 'KYC',
            website: 'Website',
            permissionToEmployeeNumber: 'Permission to Employee Number',
            nationalInsuranceNumber: 'National Insurance Number',
            valueAddedTaxNumber: 'Value Added Tax Number',
            taxIdentificationNumber: 'Tax Identification Number',
            hasADifferentCorrespondingAddress: 'Has a different corresponding address?',
            registeredOfficeAddress: 'Registered Office Address',
            residentialAddress: 'Residential Address',
            countryOfEmploymentBusiness: 'Country of Employment/Business',
            employerBusiness: 'Employer/Business',
            occupationProfession: 'Occupation/Profession',
            registeredOffice: 'Registered Office',
            dateOfIncorporation: 'Date of Incorporation',
            engagementManager: 'Engagement Manager',
            licenseNumber: 'License Number',
            additionalDetailsForPublicListedEntities: 'Additional Details for \'Public Listed Entities\'',
            additionalDetailsForRegulatedLicensedEntities: 'Additional Details for \'Regulated/Licensed Entities\'',
            additionalDetailsOnTheTradingHoldingActivities: 'Additional Details on the Trading/Holding Activities',
            removeThisQuotedService: 'Remove this quoted service',
            addQuotedService: 'Add quoted service',
            basicClientDetails: 'Basic Client Details',
            dateOfAcceptance: 'Date of Acceptance',
            date: 'Date',
            quotedServices: 'Quoted Services',
            otherUsefulInformation: 'Other Useful Information',
            primaryContactPerson: 'Primary Contact Person',
            detailsOfContactPerson: 'Details of Contact Person',
            potentialCustomerDetails: '\'Potential Customer\' Details',
            quotationAcceptance: 'Quotation Acceptance',
            quotationAccepted: 'Quotation Accepted',
            reasonForQuoteRejection: 'Reason for quote rejection',
            // otherReasonForNonAcceptance: 'Other Reason for Non-acceptance',
            countryOfIncorporation: 'Country of Incorporation',
            registrationNumber: 'Registration Number',
            entryType: 'Entry Type',
            designation: 'Designation',
            contactPersons: 'Contact Persons',
            phonePrefix: 'Phone Prefix',
            updateClient: 'Update Client',
            client: 'Client',
            companyRegistrationNumber: 'Company Registration Number',
            vatRegistrationNumber: 'Vat Registration Number',

            update: 'Update',

            updateAccountManager: 'Update Account Manager',
            accountManagerDetails: 'Account Manager Details',
            editAccountManager: 'Edit Account Manager',
            createAccountManager: 'Create Account Manager',
            contactNumber: 'Contact Number',
            accountManagerManagement: 'Account Manager Management',
            accountManagers: 'Account Managers',
            updateBusinessContact: 'Update Business Contact',
            businessContactDetails: 'Business Contact Details',
            emails: 'Emails',
            phones: 'Phones',
            companyName: 'Company Name',
            editBusinessContact: 'Edit Business Contact',
            createBusinessContact: 'Create Business Contact',
            businessContactManagement: 'Business Contact Management',
            businessContacts: 'Business Contacts (s)',
            receipts: 'Receipts',
            invoicing: 'Invoicing',
            editClient: 'Edit client',
            clientDetails: 'Client Details',
            clientManagement: 'Client Management',
            records: 'Records',
            createClient: 'Create Client',
            createTenant: 'Create Tenant',
            apply: 'Apply',
            clear: 'Clear',
            forgotYourPassword: 'Forgot your password?',
            nameOrId: 'Name or Id',
            updateUser: 'Update User',
            editUser: 'Edit User',
            userRegisteredSuccessfully: 'User registered successfully',
            userUpdatedSuccessfully: 'User updated successfully',
            unlink: 'Unlink',
            permission: 'Permission',
            description: 'Description',
            permissions: 'Permissions',
            permissionXIsSuccessfullyAddedToPermissionGroupX: 'Permission: {{permission}} is successfully added to Permission Group: {{permissionGroup}}',
            permissionXIsSuccessfullyRemovedFromPermissionGroupX: 'Permission: {{permission}} is successfully removed from Permission Group: {{permissionGroup}}',
            editPermissionGroup: 'Edit Permission Group',
            permissionGroupDetails: 'Permission Group Details',
            details: 'Details',
            logout: 'Logout',
            manageProfile: 'Manage Profile',
            user: 'User',
            associations: 'Associations',
            admin: 'Admin',
            close: 'Close',
            sendByEmail: 'Send by email',
            resetPassword: 'Reset password',
            userDetails: 'User Details',
            isAdmin: 'Is Admin',
            status: 'Status',
            actions: 'Actions',
            createdDate: 'Created Date',
            createdDateTime: 'Created Date Time',
            imagePreview: 'Image Preview',
            tenantDetails: 'Tenant Details',
            editTenant: 'Edit Tenant',
            dashboard: 'Dashboard',
            tenants: 'Tenants',
            users: 'Users',
            permissionGroupManagement: 'Permission Group Management',
            userManagement: 'User Management',
            tenantManagement: 'Tenant Management',
            confirmNewPassword: 'Confirm new password',
            passwordReset: 'Password Reset',
            confirmationSent: 'Confirmation Sent',
            send: 'Send',
            backToLogin: 'Back to login',
            forgotPassword: 'Forgot password',
            passwordChangedSuccessfullyPleaseLoginwithYourNewCredentials: 'Password changed successfully. Please login with your new credentials.',
            userVerifiedSuccessfully: 'User Verified Sucessfully',
            create: 'Create',
            tenant: 'Tenant',
            changePassword: 'Change Password',
            oldPassword: 'Old password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password',
            userVerification: 'User Verification',
            permissionGroups: 'Permission Groups',
            registerUser: 'Register User',
            logo: 'Logo',
            userStatus: 'User Status',
            query: 'Query',
            activeDirectoryId: 'Active Directory Id',
            username: 'Username',
            id: 'Id',
            name: 'Name',
            login: 'Login',
            password: 'Password',
            amount: "Amount",
            next: "Next",
            submit: 'Submit',
            email: 'Email',
            bankCardNumber: 'Card Number',
            bankCardHolderName: 'Card Holder Name',
            bankExpiryDate: 'Expiration Date',
            month: 'Month',
            year: 'Year',
            cvv: 'CVV',
            expiry: 'Expiry',
            customAmount: "Custom Amount",
            firstName: "First Name",
            lastName: "Last Name",
            fullName: "Full Name",
            dateOfBirth: "Date of Birth",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            country: "Country",
            zipPostCode: "Zip/Postcode",
            billingAddress: "Billing Address",
            deliveryAddress: "Delivery Address",
            phoneNumber: "Phone Number",
            customerInfo: "Customer Info",
            addressInfo: "Address Info",
            cardDetails: "Card Details",
            demoPayment: "Demo Payment",
            pleaseChooseATransactionTypetoProceed: "Please choose transaction type to proceed",
            newPayment: "New Payment",
            copyFromBilling: "Copy From Billing",
            includeCustomerDetails: "Include Customer Details",
            sessionExpired: "Session Expired",
            paymentComplete: "Payment Complete",
            thankYouForYourPayment: "Thank you for your payment",
            paymentFailed: "Payment Failed",
            pleaseContactSupport: "Please contact support",
            invalidTransaction: 'Invalid Transaction',
            cardNumber: 'Card Number',
            thePageYouAreLookingforIsntHere: 'The page you are looking for isn’t here',

          },
          validations: {
            quoteRejectionReasonIsRequired: 'Quote rejection reason is required',
            quoteCancellationReasonIsRequired: 'Quote cancellation reason is required',
            receiptCancellationReasonIsRequired: 'Receipt cancellation reason is required',
            mustbeLessOrEqualToBalanceDueOnInvoice: 'Must be less or equal to balance due on invoice',
            receiptValueMustMatchTheSumOfAllocations: 'Receipt amount must match the sum of all allocations',
            mustHaveAtLeast1Invoice: 'Must have at least one invoice',
            receiptDateIsRequired: 'Receipt date is required',
            invoiceDateIsRequired: 'Invoice date is required',
            allocatedValueCannotExceedBalanceDue: 'Allocated value cannot exceed Balance Due',
            totalAllocatedCreditCannotExceedCreditNoteValue: 'Total allocated credit cannot exceed Credit Note value',

            mustMatchAmountInAllocation: 'Must match amount in allocation',
            mustHaveAtLeast1Service: 'Must have at least one service',
            quotationDateIsRequired: 'Quotation Date is required',
            signatoryIsRequired: 'Signatory is required',
            dateCannotBeInTheFuture: 'Date cannnot be in the future',

            StartTimeAndEndTimeOrTimeSpentMustBeProvided: 'Start time and end time or Time Spent must be provided',
            atLeastOnModificationMustBeProvidedForBulkUpdate: 'At least one modification must be provided for bulk update',
            endTimeProvidedOccursBeforeTheStartTime: 'End time provided occurs before the start time',
            timeSpentMustNotExceed2DecimalSpaces: 'Time Spent must not exceed 2 decimal spaces',
            timeSpentMustBeGreaterThan0: 'Time Spent must be greater than 0',
            timeSpentMustBeANumber: 'Time Spent must be a number',
            atLeastOneInvoiceIsRequired: 'At least one Invoice is required',
            atLeastOneTimesheetMustBeIncluded: 'At least one Timesheet must be included.',
            atLeastOneInvoiceManualInputIsRequired: 'At least one Invoice Manual Input is required',
            lapsedMustBeGreaterThan0: 'Elapsed must be greater than 0',
            currencyIsRequired: 'Currency is required',
            tagIsRequired: 'Tag is required',
            vatIsRequired: 'VAT is required',
            activitiesTakingPlaceInNoteIsRequired: 'Activities Taking Place In Infor is required',
            businessActivitiesDescriptionIsRequired: 'Business Activities Description is required',
            cashIntensiveBusinessIsRequired: 'Cash Intensive Business is required',
            dualUseItemsIsRequired: 'Dual Use Items is required',
            expectedTotalIncomeIsRequired: 'Expected Total Income is required',
            fieldIsRequired: 'Field is required',
            countryOfBusinessIsRequired: 'Country of business is required',
            mustChooseAtLeastOne: 'Must choose at least one',
            occupationProfessionIsRequired: 'Occupation/Profession is required',
            mustBeLettersAndNumbersOnly: 'Must contain alphanumeric characters only',
            dateIsRequired: 'Date is required',
            dateIsInvalid: 'Date is invalid',
            startDateIsRequired: 'Start date is required',
            startDateIsInvalid: 'Start date is invalid',
            endDateIsRequired: 'End date is required',
            endDateIsInvalid: 'End date is invalid',
            workYearIsRequired: 'Work year is required',
            workYearIsInvalid: 'Work year is invalid',
            titleIsRequired: 'Title is required',
            serviceIsRequired: 'Service is required',
            legalEntityTypeIsRequired: 'Legal Entity Type is required',
            placeOfBirthIsRequired: 'Place of Birth is required',
            genderIsRequired: 'Gender is required',
            townIsRequired: 'Town is required',
            industryIsRequired: 'Industry is required',
            sectorIsRequired: 'Sector is required',
            licenseCategoryIsRequired: 'License Category is required',
            nameOfRegulatorIsRequired: 'Name of Regulator is required',
            jurisdictionOfRegulatorIsRequired: 'Jurisdiction of Regulator is required',
            typeOfListingIsRequired: 'Type of Listing is required',
            investmentTypeIsRequired: 'Investment Type is required',
            highRiskIndustryIsRequired: 'High Risk Industry is required',
            relationshipIsRequired: 'Relationship is required',
            layerOfOwnershipIsRequired: 'Layer of Ownership is required',
            detailsOfSchemeIsRequired: 'Details of Scheme is required',
            statusOfApplicationIsRequired: 'Status of Application is required',
            pepRelationshipIsRequired: 'PEP Relationship is required',
            introducedByIsRequired: 'Introduced By is required',
            dealingDirectlyWithIsRequired: 'Dealing Directly With is required',
            identityDocumentTypeIsRequired: 'Identity Document Type is required',
            proofOfAddressIsRequired: 'Proof of Address is required',
            estimatedNetWorthIsRequired: 'Estimated Net Worth is required',
            reasonForNotOnboardingIsRequired: 'Reason For Not Onboarding is required',
            reasonForTerminationIsRequired: 'Reason For Termination is required',
            curencyIsRequired: 'Currency is required',
            timesheetTypeIsRequired: 'Timesheet Type is required',

            valueIsRequired: 'Value is required',
            reasonForQuoteRejectionIsRequired: 'Reason for quote rejection is required',
            hasADifferentCorrespondingAddressIsRequired: 'Has a different corresponding address is required',
            quotationAcceptedOrRejectedIsRequired: 'Quotation Accepted or Rejected is required',
            designationIsRequired: 'Designation is Required',
            taxIdentificationNumberIsRequired: 'Tax Idenfication Number is requried',
            websiteIsRequired: 'Website is required',
            permissionToEmployeeNumberIsRequired: 'Permission to Employee Number is requried',
            nationalInsuranceNumberIsRequired: 'National Insurance Number is required',
            valueAddedTaxNumberIsRequired: 'Value Added Tax Number is required',
            engagementManagerIsRequired: 'Engagement Manager is required',
            // otherReasonForNonAcceptanceIsRequired: 'Other Reason for Non-Acceptance is required',
            entryTypeIsRequired: 'Entry Type is required',
            licenseNumberIsRequired: 'License Number is required',
            dateOfAcceptanceIsRequired: 'Date of Acceptance is required',
            otherUsefulInformationIsRequired: 'Other useful information is required',
            countryOfIncorporationIsRequired: 'Country of Incorporation is required',
            registrationNumberIsRequired: 'Registration number is required',
            primaryContactPersonIsRequired: 'Primary contact person is required',
            clientStatusIsRequired: 'Client Status is required',
            userStatusIsRequired: 'User Status is required',
            contactNumberIsRequired: 'Contact number is required',
            accountManagerIsRequired: 'Account manager is required',
            companyNameIsRequired: 'Company name is required',
            tenantWithThatNameAlreadyExists: 'A tenant with that Name already exists',
            tenantWithThatNameOrIdDoesNotExist: 'A tenant with that Name or Id does not exist',
            nameMustBeLessThan100Characters: 'Name must be less than 100 Characters',
            descriptionMustBeLessThan100Characters: 'Description must be less than 100 Characters',
            descriptionIsRequired: 'Description is required',
            nameCanOnlyContainLettersNumbersDashOrUnderscore: 'Name can only contain letters, numbers, dashes or underscores',
            imageIsRequired: 'Image is required',
            userIsRequired: 'User is required',
            emailFormatIsInvalid: 'Email format is invalid',
            emailIsRequired: 'Email is required',
            usernameIsRequired: 'Username is required',
            nameIsRequired: 'Name is required',
            tenantIsRequired: 'Tenant is required',
            atLeastOnePermissionGroupNeedsToBeSelected: 'At least one Permission Group needs to be selected',
            activeDirectoryIdIsRequired: 'Active directory Id is required',
            passwordIsRequired: 'Password is required',
            oldPasswordIsRequired: 'Old password is requried',
            newPasswordIsRequired: 'New password is requried',
            confirmPasswordIsRequired: 'Confirm password is required',
            passwordsMustMatch: 'Passwords must match',
            amountIsRequired: 'Amount is required',
            amountMustBeANumber: 'Amount must be a number',
            valueMustBeANumber: 'Value must be a number',
            amountMustBeGreaterThan0: 'Amount must greater than 0',
            amountMustBeLessThan0: 'Amount must be less than 0',
            valueMustBeGreaterThan0: 'Value must greater than 0',
            valueMustBeLessOrEqualTo100: 'Value must less or equal to 100',
            amountMustNotExceed2DecimalSpaces: 'Amount must not exceed 2 decimal spaces',
            dateOfBirthIsRequired: 'Date of birth is required',
            firstNameIsRequired: 'First name is required',
            lastNameIsRequired: 'Last name is required',
            phoneCountryCodeIsRequired: 'Phone country code is required',
            mustSelectAPhonePrefix: 'Must select a phone prefix',
            phoneNumberIsRequired: 'Phone number is required',
            invalidPhonenumberOrCountryCode: 'Invalid phone number or country code',
            mustSelectACountry: 'Must select a country',
            addressIsRequired: 'Address is required',
            postcodeIsRequired: 'Zip/Post code is required',
            cityIsRequired: 'City is required',
            stateIsRequired: 'State is required',
            countryIsRequired: 'Country is required',
            creditCardNumberIsInvalid: 'Credit Card number is invalid',
            nameIsInvalid: 'Name is invalid',
            expiryMonthIsInvalid: 'Expiry month is invalid',
            expiryYearIsInvalid: 'Expiry year is invalid',
            cvvNumberIsInvalid: 'CVV number is invalid',
            mustBeAValidEmail: 'Must be a valid email address',
            billerIsRequired: "Biller is required",
            recordIsRequired: 'Record is required',
            timesheetInputTypeIsRequired: "Timesheet input type is required",
            reviewerIsRequired: 'Reviewer is required'

          },
          errors: {
            receiptCannotBeLinkedToACreditNote: 'Receipt Cannot be linked to a Credit Note',
            dataCannotBeUpdated: 'Data cannot be updated',
            dateCannotBeInTheFuture: 'Date cannot be in the future',
            notACreditNote: 'Not a Credit Note',
            invalidInvoiceDate: 'Invalid invoice date',
            reviewerIsRequired: 'Reviewer is required',
            signatoryIsRequired: 'Signatory is required',
            allocationsAreRequired: 'Allocations are required',
            invalidAllocations: 'Invalid allocations',
            quotationCannotBeCanceled: 'Quotation cennot be canceled',
            valueTooLong: 'One of the values is too long',
            allocationAndManualInputsTotalsMustMatch: 'Allocation and Manual Input totals Must Match',
            rateIsMissing: 'Rate is missing for one or some of the timesheets',
            rateAlreadyExists: 'Rate already exists',
            expiredToken: 'Expired Token',
            currencyAlreadyExists: 'Currency already exists',
            licenseCategoryAlreadyExists: 'License Category already exists',
            industryAlreadyExists: 'Industry already exists',
            legalEntityTypeAlreadyExists: 'Legal Entity Type already exists',
            serviceAlreadyExists: 'Service already exists',
            timesheetTypeAlreadyExists: 'Timesheet-Type already exists',
            titleAlreadyExists: 'Title already exists',
            genderAlreadyExists: 'Gender already exists',
            layerOfOwnershipAlreadyExists: 'Layer of Ownership already exists',
            invalidToken: 'Invalid token',
            authenticationServerError: 'Authentication server error',
            yourAccountHasBeenSuspendedPleaseContactAnAdministrator: 'Your account has been suspended. Please contact an administrator',
            yourAccountIsStillPendingVerificationPleaseVerifyTheAccountBeforeLoggingIn: 'Your account is still pending verification. Please verify the account before logging in',
            wrongEmailOrPassword: 'Wrong email or password',
            accountIsLocked: 'Account is locked',
            passwordExpiredPleaseChangePassword: 'Password expired. Please change password',
            accessDenied: ' Access Denied',
            emailOrPasswordDoNotMatch: 'Email or Password do not match',
            invalidTenantId: 'Invalid Tenant Id',
            tenantAlreadyExists: 'Tenant already exists',
            permissionGroupAlreadyExists: 'Permission Group already exists',
            anAccountWithThisEmailAlreadyExists: 'An account with this email already exists',
            userAlreadyVerified: 'User already verified',
            passwordDoesNotMatched: 'Password does not match',
            somethingWentWrong: 'Something went wrong',
            verificationExpired: 'Verification Expired',
            verificationFailed: 'Verification Failed',
            cannotSetOldOrSamePassword: 'Cannot set old or same password',
            usernameNotFound: 'Username not found',
            fieldValidationError: 'Field validation error'
          }

        }
      },
      es: {
        translation: {
          common: {
            amount: "Monto",
            currency: "Divisa",
            next: "Próximo",
            submit: 'Enviar',
            email: 'Email',
            bankCardNumber: 'Número de tarjeta',
            bankCardHolderName: 'Titular de la Tarjeta',
            bankExpiryDate: 'Fecha de Expiracion',
            month: 'Mes',
            year: 'Año',
            cvv: 'CVV',
            expiry: 'Expiración',
            customAmount: 'Otro valor',
            title: "Título",
            firstName: "Primer nombre",
            lastName: "Apellido",
            fullName: "Nombre completo",
            dateOfBirth: "Fecha de nacimiento",
            addressLine1: "Dirección Línea 1",
            addressLine2: "Dirección Línea 2",
            city: "Ciudad",
            state: "Estado",
            country: "País",
            zipPostCode: "Código postal",
            billingAddress: "Dirección de Envio",
            deliveryAddress: "Dirección de entrega",
            phoneNumber: "Número de teléfono",
            customerInfo: "Información del cliente",
            addressInfo: "Información de la dirección",
            cardDetails: "Detalles de tarjeta",
            demoPayment: "Pago de demostración",
            pleaseChooseATransactionTypetoProceed: "Elija el tipo de transacción para continuar",
            newPayment: "Nuevo pago",
            copyFromBilling: 'Copiar de facturación',
            includeCustomerDetails: "Incluir detalles del cliente",
            sessionExpired: "Sesión expirada",
            paymentComplete: "Pago completo",
            thankYouForYourPayment: "Gracias por tu pago",
            paymentFailed: "Pago fallido",
            pleaseContactSupport: "Póngase en contacto con soporte",
            invalidTransaction: 'Transacción Inválida',
            cardNumber: 'Número de tarjeta',
            thePageYouAreLookingforIsntHere: 'La página que buscas no está aquí',
          },
          validations: {
            amountIsRequired: 'Se requiere cantidad',
            amountMustBeANumber: 'La cantidad debe ser un número',
            amountMustBeGreaterThan0: 'La cantidad debe ser mayor que 0',
            amountMustNotExceed2DecimalSpaces: 'La cantidad no debe exceder los 2 decimales',
            titleIsRequired: 'Se requiere título',
            emailIsRequired: 'Correo electronico es requerido',
            dateOfBirthIsRequired: 'Se requiere la fecha de nacimiento',
            firstNameIsRequired: 'Se requiere el primer nombre',
            lastNameIsRequired: 'Se requiere apellido',
            phoneCountryCodeIsRequired: 'Se requiere el código de país del teléfono',
            phoneNumberIsRequired: 'El número de teléfono es obligatorio',
            invalidPhonenumberOrCountryCode: 'Número de teléfono o código de país no válido',
            mustSelectACountry: 'Debe seleccionar un país',
            addressIsRequired: 'La dirección es necesaria',
            postcodeIsRequired: 'Se requiere código postal',
            cityIsRequired: 'Ciudad es requerida',
            stateIsRequired: 'Se requiere estado',
            countryIsRequired: 'El país es obligatorio',
            creditCardNumberIsInvalid: 'El número de la tarjeta de crédito es inválido',
            nameIsInvalid: 'El nombre no es válido',
            expiryMonthIsInvalid: 'El mes de caducidad no es válido',
            expiryYearIsInvalid: 'El año de caducidad no es válido',
            cvvNumberIsInvalid: 'El número de CVV no es válido',
            mustBeAValidEmail: 'Debe ser una dirección de correo electrónico válida',
          },
          voucher: {
            voucher: 'Cupón',
            buy: 'Comprar',
            redeem: 'Canjear',
            redeemVoucher: 'Canjear cupón',
            voucherNumber: 'Número de Cupón',
            voucherCodeRedeemedSuccessfully: 'Código de cupón canjeado con éxito',
            validations: {
              codeIsRequired: 'Se requiere código de cupón',
              codeShouldBe16CharactersLong: 'El código de cupón debe tener exactamente 16 caracteres'
            },
            errors: {
              invalidVoucher: 'Cupón invalido',
              voucherNotAccepted: 'Cupón no aceptado',
              voucherAlreadyRedeemed: 'Cupón ya canjeado',
              voucherLocked: 'Cupón bloqueado',
              voucherInactive: 'Cupón inactivo',
              voucherExpired: 'Cupón caducado',
              voucherNotFound: 'Cupón no encontrado',
              voucherError: 'Error de cupón'
            }
          }
        }
      },
      pt: {
        translation: {
          common: {
            amount: "Montante",
            next: "Próximo",
            submit: 'Enviar',
            email: 'Email',
            bankCardNumber: 'Número do cartão',
            bankCardHolderName: 'Titular do Cartão',
            bankExpiryDate: 'Data de expiração ',
            month: 'Mês',
            year: 'Ano',
            cvv: 'CVV',
            expiry: 'Termo',
            customAmount: "Outro valor",
            title: "Título",
            firstName: "Nome",
            lastName: "Sobrenome",
            fullName: "Nome completo",
            dateOfBirth: "Data de nascimento",
            addressLine1: "Endereço Linha 1",
            addressLine2: "Endereço Linha 2",
            city: "Cidade",
            state: "Estado",
            country: "País",
            zipPostCode: "Código postal",
            billingAddress: "Endereço de cobrança",
            deliveryAddress: "Endereço de entrega",
            phoneNumber: "Número de Telefone",
            customerInfo: "Informação do Cliente",
            addressInfo: "Informações de endereço",
            cardDetails: "Detalhes do cartão",
            demoPayment: "Pagamento de demonstração",
            pleaseChooseATransactionTypetoProceed: "Por favor, escolha o tipo de transação para continuar",
            newPayment: "Novo pagamento",
            copyFromBilling: "Copiar do faturamento",
            includeCustomerDetails: "Incluir detalhes do cliente",
            sessionExpired: "Sessão expirada",
            paymentComplete: "Pagamento Concluído",
            thankYouForYourPayment: "Obrigado por seu pagamento",
            paymentFailed: "Erro no Pagamento",
            pleaseContactSupport: "Entre em contato com o nosso suporte",
            invalidTransaction: 'Transacção inválida',
            cardNumber: 'Número do cartão',
            thePageYouAreLookingforIsntHere: 'A página que você está procurando não está aqui',
          },

          validations: {
            amountIsRequired: 'O valor é obrigatório',
            amountMustBeANumber: 'O valor deve ser um número',
            amountMustBeGreaterThan0: 'O valor deve ser maior que 0',
            amountMustNotExceed2DecimalSpaces: 'O valor não deve exceder 2 casas decimais',

            titleIsRequired: 'O título é obrigatório',
            emailIsRequired: 'O e-mail é obrigatório',
            dateOfBirthIsRequired: 'A data de nascimento é obrigatória',
            firstNameIsRequired: 'O primeiro nome é necessário',
            lastNameIsRequired: 'O sobrenome é obrigatório',
            phoneCountryCodeIsRequired: 'O código do país do telefone é obrigatório',
            phoneNumberIsRequired: 'O número de telefone é obrigatório',
            invalidPhonenumberOrCountryCode: 'Número de telefone ou código de país inválido',
            mustSelectACountry: 'Deve selecionar um país',
            addressIsRequired: 'Endereço é necessário',
            postcodeIsRequired: 'Código Postal é obrigatório',
            cityIsRequired: 'A cidade é obrigatória',
            stateIsRequired: 'O estado é obrigatório',
            countryIsRequired: 'O país é obrigatório',
            creditCardNumberIsInvalid: 'O número do cartão de crédito é inválido',
            nameIsInvalid: 'O nome é inválido',
            expiryMonthIsInvalid: 'O mês de expiração é inválido',
            expiryYearIsInvalid: 'O ano de expiração é inválido',
            cvvNumberIsInvalid: 'Número CVV é inválido',
            mustBeAValidEmail: 'Deve ser um endereço de e-mail válido',
          },
          voucher: {
            voucher: 'Cupom',
            buy: 'Comprar',
            redeem: 'Resgatar',
            redeemVoucher: 'Resgatar cupom',
            voucherNumber: 'Número do cupom',
            voucherCodeRedeemedSuccessfully: 'Código de cupom resgatado com sucesso',
            validations: {
              codeIsRequired: 'Código de cupom necessário',
              codeShouldBe16CharactersLong: 'O código do cupom deve ter exatamente 16 caracteres'
            },
            errors: {
              invalidVoucher: 'Cupom inválido',
              voucherNotAccepted: 'Cupom não aceito',
              voucherAlreadyRedeemed: 'Cupom já resgatado',
              voucherLocked: 'Cupom bloqueado',
              voucherInactive: 'Cupom inativo',
              voucherExpired: 'Cupom expirado',
              voucherNotFound: 'Cupom não encontrado',
              voucherError: 'Erro de cupom'
            }
          }
        }
      },
      nl: {
        translation: {
          voucher: {
            voucher: 'Waardebon',
            buy: 'Kopen',
            redeem: 'Inwisselen',
            redeemVoucher: 'Waardebon valideren',
            voucherNumber: 'Vouchernummer',
            voucherCodeRedeemedSuccessfully: 'Vouchercode succesvol ingewisseld',
            validations: {
              codeIsRequired: 'Vouchercode is vereist',
              codeShouldBe16CharactersLong: 'De vouchercode moet exact 16 tekens lang zijn'
            },
            errors: {
              invalidVoucher: 'Ongeldige voucher',
              voucherNotAccepted: 'Voucher niet geaccepteerd',
              voucherAlreadyRedeemed: 'Voucher al ingewisseld',
              voucherLocked: 'Voucher vergrendeld',
              voucherInactive: 'Voucher inactief',
              voucherExpired: 'Voucher verlopen',
              voucherNotFound: 'Waardebon niet gevonden',
              voucherError: 'Voucherfout'
            }
          }
        }
      },
      de: {
        translation: {
          voucher: {
            voucher: 'Gutschein',
            buy: 'Besorgen',
            redeem: 'Tilgen',
            redeemVoucher: 'Gutschein einlösen',
            voucherNumber: 'Gutscheinnummer',
            voucherCodeRedeemedSuccessfully: 'Gutscheincode erfolgreich eingelöst',
            validations: {
              codeIsRequired: 'Gutscheincode ist erforderlich',
              codeShouldBe16CharactersLong: 'Der Gutscheincode sollte genau 16 Zeichen lang sein'
            },
            errors: {
              invalidVoucher: 'Ungültige Gutschein',
              voucherNotAccepted: 'Gutschein nicht akzeptiert',
              voucherAlreadyRedeemed: 'Gutschein bereits eingelöst',
              voucherLocked: 'Gutschein gesperrt',
              voucherInactive: 'Gutschein inaktiv',
              voucherExpired: 'Gutschein abgelaufen',
              voucherNotFound: 'Gutschein nicht gefunden',
              voucherError: 'Gutscheinfehler'
            }
          }
        }
      },
      fr: {
        translation: {
          voucher: {
            voucher: 'Bon',
            buy: 'Acheter',
            redeem: 'Racheter',
            redeemVoucher: 'Utiliser le bon',
            voucherNumber: 'Numéro du bon',
            voucherCodeRedeemedSuccessfully: 'Code de bon utilisé avec succès',
            validations: {
              codeIsRequired: 'Le code de bon est requis',
              codeShouldBe16CharactersLong: 'Le code du bon doit comporter exactement 16 caractères'
            },
            errors: {
              invalidVoucher: 'Coupon invalide',
              voucherNotAccepted: 'Chèque non accepté',
              voucherAlreadyRedeemed: 'Bon déjà utilisé',
              voucherLocked: 'Bon verrouillé',
              voucherInactive: 'Coupon inactif',
              voucherExpired: 'Bon expiré',
              voucherNotFound: 'Coupon introuvable',
              voucherError: 'Erreur de bon'
            }
          }

        }
      },
      be: {
        translation: {
          voucher: {
            voucher: 'Waardebon',
            buy: 'Kopen',
            redeem: 'Inwisselen',
            redeemVoucher: 'Waardebon valideren',
            voucherNumber: 'Vouchernummer',
            voucherCodeRedeemedSuccessfully: 'Vouchercode succesvol ingewisseld',
            validations: {
              codeIsRequired: 'Vouchercode is vereist',
              codeShouldBe16CharactersLong: 'De vouchercode moet exact 16 tekens lang zijn'
            },
            errors: {
              invalidVoucher: 'Ongeldige voucher',
              voucherNotAccepted: 'Voucher niet geaccepteerd',
              voucherAlreadyRedeemed: 'Voucher al ingewisseld',
              voucherLocked: 'Voucher vergrendeld',
              voucherInactive: 'Voucher inactief',
              voucherExpired: 'Voucher verlopen',
              voucherNotFound: 'Waardebon niet gevonden',
              voucherError: 'Voucherfout'
            }
          }
        }
      }
    }
  });

export default i18n;