import { Color, colors } from "@mui/material";
import { ApiReceiptStatus } from ".";


export const SUBMITTED_MANUALLY_RECEIPT_STATUS = 'SUBMITTED_MANUALLY';
export const CANCELLED_RECEIPT_STATUS = 'CANCELLED';
export const FINAL_RECEIPT_STATUS = 'FINAL';
export const SENT_RECEIPT_STATUS = 'SENT';
export const NOT_SENT_RECEIPT_STATUS = 'NOT_SENT';


export const receiptStatuses = [SUBMITTED_MANUALLY_RECEIPT_STATUS,
    CANCELLED_RECEIPT_STATUS,
    FINAL_RECEIPT_STATUS, SENT_RECEIPT_STATUS, NOT_SENT_RECEIPT_STATUS] as const;
export const receiptStatusesColours = [colors.orange, colors.red, colors.green, colors.green, colors.red];
export const receiptStatusesColourMap = new Map<ApiReceiptStatus, Color>(receiptStatuses.map((v, i) => [v, receiptStatusesColours[i]]));;
