import * as React from "react";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Brush,
  SupervisorAccount,
  FolderShared,
  People,
  Security,
  Groups,
  ExpandMore,
  ExpandLess,
  RequestQuote,
  PunchClock,
  Approval,
  Dashboard,
  Work,
} from "@mui/icons-material";
import { NavigateFunction } from "react-router-dom";
import { PermissionGuard } from "../../../models/auth";
import { TFunction } from "i18next";
import { Tooltip } from "@mui/material";
import { enumerationDataMap } from "../../../screens/Enumerations/utils";
import { GET_BILLER } from "../../../models/permissionGroups";

export const mainListItems = (
  tenantNameOrId: string,
  navigate: NavigateFunction,
  t: TFunction<"translation", undefined, "translation">,
  toggleDrawer: () => void,
  openMenuItem: boolean,
  setOpenMenuItem: (openMenuItem: boolean) => void
) => (
  <React.Fragment>
    <ListItemButton
      onClick={(event) => {
        navigate(`/${tenantNameOrId}/dashboard`);
      }}
    >
      <Tooltip title={t("common.dashboard")}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={t("common.dashboard")} />
    </ListItemButton>

    <PermissionGuard permission="GET_TENANT">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/tenants`);
          toggleDrawer();
        }}
      >
        <Tooltip title={t("common.tenants")}>
          <ListItemIcon>
            <FolderShared />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.tenants")} />
      </ListItemButton>
    </PermissionGuard>

    <PermissionGuard permission="GET_ENTITY">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/records`);
        }}
      >
        <Tooltip title={t("common.records")}>
          <ListItemIcon>
            <Groups />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.records")} />
      </ListItemButton>
    </PermissionGuard>

    <ListItemButton
      onClick={(event) => {
        navigate(`/${tenantNameOrId}/approvals`);
      }}
    >
      <Tooltip title={t("common.approvals")}>
        <ListItemIcon>
          <Approval />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={t("common.approvals")} />
    </ListItemButton>

    <PermissionGuard permission="GET_USER">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/users`);
        }}
      >
        <Tooltip title={t("common.users")}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.users")} />
      </ListItemButton>
    </PermissionGuard>

    <PermissionGuard permission="GET_PERMISSION_GROUP">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/permission-groups`);
        }}
      >
        <Tooltip title={t("common.permissionGroups")}>
          <ListItemIcon>
            <Security />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.permissionGroups")} />
      </ListItemButton>
    </PermissionGuard>
    {/* need permission for business contact */}
    {/* <ListItemButton
      onClick={(event) => {
        navigate(`/${tenantNameOrId}/business-contacts`);
      }}
    >
      <Tooltip title={t("common.businessContacts")}>
        <ListItemIcon>
          <Contacts />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={t("common.businessContacts")} />
    </ListItemButton> */}

    {/* <PermissionGuard permission="ADMIN">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/legal-entity-types`);
        }}
      >
        <Tooltip title={t("common.legalEntityTypes")}>
          <ListItemIcon>
            <Business />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.legalEntityTypes")} />
      </ListItemButton>
    </PermissionGuard> */}

    {/* <PermissionGuard permission="ADMIN">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/industries`);
        }}
      >
        <Tooltip title={t("common.industries")}>
          <ListItemIcon>
            <CorporateFare />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.industries")} />
      </ListItemButton>
    </PermissionGuard> */}

    {/* need permission for account managers */}

    <PermissionGuard permission={GET_BILLER}>
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/billers`);
        }}
      >
        <Tooltip title={t("common.billers")}>
          <ListItemIcon>
            <RequestQuote />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.billers")} />
      </ListItemButton>
    </PermissionGuard>

    <PermissionGuard permission="GET_TIMESHEET">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/timesheets`);
        }}
      >
        <Tooltip title={t("common.timesheets")}>
          <ListItemIcon>
            <PunchClock />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.timesheets")} />
      </ListItemButton>
    </PermissionGuard>

    <ListItem
      disableGutters
      sx={{
        display: "block",
        py: 0,
      }}
    >
      <ListItemButton
        onClick={() => setOpenMenuItem(!openMenuItem)}
        sx={(theme) => ({
          ...(openMenuItem
            ? {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }
            : {}),
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        })}
      >
        <Tooltip title={t("common.systemConfigurations")}>
          <ListItemIcon
            sx={(theme) => ({
              ...(openMenuItem
                ? {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }
                : {}),
            })}
          >
            {openMenuItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.systemConfigurations")} />
      </ListItemButton>

      <Collapse in={openMenuItem}>
        {/* <PermissionGuard permission="CREATE_SERVICE"> */}
        <ListItemButton
          onClick={(event) => {
            navigate(`/${tenantNameOrId}/services`);
          }}
        >
          <Tooltip title={t("common.services")}>
            <ListItemIcon>
              <Brush />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("common.services")} />
        </ListItemButton>

        <ListItemButton
          onClick={(event) => {
            navigate(`/${tenantNameOrId}/signatories`);
          }}
        >
          <Tooltip title={t("common.signatories")}>
            <ListItemIcon>
              <Work />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("common.signatories")} />
        </ListItemButton>
        {/* </PermissionGuard> */}
        {Array.from(enumerationDataMap.keys()).map((key) =>
          enumerationDataMap.get(key).show ? (
            <PermissionGuard permission="ADMIN" key={key}>
              <ListItemButton
                onClick={(event) => {
                  navigate(
                    `/${tenantNameOrId}/${key
                      .toLowerCase()
                      .replaceAll("_", "-")}`
                  );
                }}
              >
                <Tooltip title={t(enumerationDataMap.get(key).enumerations)}>
                  <ListItemIcon>
                    {enumerationDataMap.get(key).icon}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t(enumerationDataMap.get(key).enumerations)}
                />
              </ListItemButton>
            </PermissionGuard>
          ) : null
        )}
      </Collapse>
    </ListItem>

    {/* <PermissionGuard permission="ADMIN">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/services`);
        }}
      >
        <Tooltip title={t("common.services")}>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.services")} />
      </ListItemButton>
    </PermissionGuard> */}

    {/* <PermissionGuard permission="ADMIN">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/timesheet-type`);
        }}
      >
        <Tooltip title={t("common.timesheetTypes")}>
          <ListItemIcon>
            <TextFields />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.timesheetTypes")} />
      </ListItemButton>
    </PermissionGuard> */}

    {/* <PermissionGuard permission="ADMIN">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/titles`);
        }}
      >
        <Tooltip title={t("common.titles")}>
          <ListItemIcon>
            <Title />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.titles")} />
      </ListItemButton>
    </PermissionGuard> */}

    {/* <PermissionGuard permission="ADMIN">
      <ListItemButton
        onClick={(event) => {
          navigate(`/${tenantNameOrId}/genders`);
        }}
      >
        <Tooltip title={t("common.genders")}>
          <ListItemIcon>
            <Wc />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("common.genders")} />
      </ListItemButton>
    </PermissionGuard> */}
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);
