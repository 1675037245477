import * as Yup from "yup";

export const entityValidationSchema = (validationMessage?: string) =>
    Yup.object()
        .required(validationMessage || 'validations.recordIsRequired')
        .nullable()
        .test(
            'null-check',
            validationMessage || 'validations.recordIsRequired',
            (entity) => entity != null,
        )
