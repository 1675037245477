import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  Container,
  Drawer,
  Grid,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  colors,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { PermissionGuard, usePermissions } from "../../../models/auth";
import { useEntityQuery } from "../../../models/entities";
import { LazyLoadableScreen } from "../../../navigation/LazyLoadableScreen";
// import { InvoicesRoutes } from "./Invoices";
// import { ReceiptsRoutes } from "./Receipts";
import {
  ApiEntityStatus,
  LEGAL_ENTITY,
  NATURAL_PERSON,
  entityStatusesColourMap,
} from "../../../forms";
// import { QuotationsRoutes } from "./Quotations";
import { format } from "date-fns";
import { StatusLabel } from "../../../components/StatusLabel";
import {
  ApiUserResponse,
  useReviewersEntitiesListQuery,
  useUsersListQuery,
} from "../../../models/users";
import { Nullable } from "../../../utils/types";
// import { UpdateEntityForm } from "../forms";
import { SIDEBAR_WIDTH } from "../../../components/Layouts/Dashboard/utils/config";
import { useEnumerationsQuery } from "../../../models/enumerations";
import { QuotationApprovalsRoutes } from "./QuotationApprovals";
import { EntityApprovalsRoutes } from "./EntityApprovals";
// import { QuotationsRoutes } from "../../../business/Entities/EntityTabs/Quotations";

// const QuotationApprovalsScreen = LazyLoadableScreen(
//   () => import("./QuotationApprovals")
// );
// const StageLogScreen = LazyLoadableScreen(() => import("./StageLogDetails"));

export const ApprovalTabs: FC<{}> = ({}) => {
  const { t } = useTranslation();
  const location = useLocation();
  // const { hasPermission } = usePermissions();
  // const { tenantNameOrId } = useParams<{ tenantNameOrId: string }>();
  const { id } = useParams<{ id: string }>();
  const [updateEntityOpened, setUpdateEntityOpened] = useState<boolean>(false);
  // const [createdBy, setCreatedBy] = useState<Nullable<ApiUserResponse>>(null);
  // const [updatedBy, setUpdatedBy] = useState<Nullable<ApiUserResponse>>(null);

  // const { data: enumerables } = useEnumerationsQuery(
  //   {},
  //   {
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  // const { data: reviewers } = useReviewersEntitiesListQuery(
  //   {},
  //   { refetchOnMountOrArgChange: true }
  // );

  // const { data: entity } = useEntityQuery(
  //   { id: id as string },
  //   { refetchOnMountOrArgChange: true }
  // );

  // const { data: users } = useUsersListQuery({
  //   refetchOnMountOrArgChange: true,
  // });

  // useEffect(() => {
  //   if (entity && users) {
  //     setCreatedBy(
  //       entity.createdByUserId
  //         ? users.find((x) => x.id === entity.createdByUserId)!
  //         : null
  //     );
  //     setUpdatedBy(
  //       entity.updatedByUserId
  //         ? users.find((x) => x.id === entity.updatedByUserId)!
  //         : null
  //     );
  //   }
  // }, [entity, users]);

  // const breadcrumbs = [
  //   <Link key="1" color={blueGrey[600]} component={RouterLink} to={"./.."}>
  //     <Typography variant="overline">{t("common.recordManagement")}</Typography>
  //   </Link>,
  //   <Link
  //     key="1"
  //     color={blueGrey[500]}
  //     component={RouterLink}
  //     to={`./../${id}`}
  //   >
  //     <Typography variant="overline"> {t("common.records")}</Typography>
  //   </Link>,
  // ];

  interface TabRoutes {
    show: boolean;
    route1: string;
    route2: string;
    label: string;
    component: ReactElement<any, any>;
  }

  interface DetailRow {
    name: string;
    value: any;
  }

  const routes: TabRoutes[] = useMemo(
    () => [
      {
        show: true,
        route1: "quotations",
        route2: "quotations/*",
        label: "common.quotations",
        component: <QuotationApprovalsRoutes />,
      },
      {
        show: true,
        route1: "entities",
        route2: "entities/*",
        label: "common.records",
        component: <EntityApprovalsRoutes />,
      },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>{t("common.approvals")}</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <Grid alignItems="center" container item xs={12}>
            <Typography
              sx={{ color: colors.blueGrey[600], textTransform: "uppercase" }}
              variant="overline"
            >
              {t("common.approvals")}
            </Typography>
          </Grid>
          <PermissionGuard permission="GET_ENTITY">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={
                  location.pathname.split("/").length > 3
                    ? `${location.pathname.split("/")[3]}`
                    : routes[0].route1 // fallback details
                }
                aria-label="entity"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {routes.map((elem, index) => {
                  return (
                    <Tab
                      key={elem.route1}
                      style={{ minWidth: 50 }}
                      label={t(elem.label)}
                      value={`${elem.route1}`}
                      component={RouterLink}
                      to={elem.route1}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Routes>
              {routes.map((elem, index) => {
                return (
                  <Route
                    key={elem.label}
                    path={elem.route2}
                    element={<>{elem.component}</>}
                  />
                );
              })}

              <Route
                path="details"
                element={<>{/* <EntityDetailsScreen id={id as string} /> */}</>}
              />
              <Route path="*" element={<Navigate to="quotations" replace />} />
            </Routes>
          </PermissionGuard>
        </Container>
      </Box>

      <PermissionGuard permission="CREATE_ENTITY">
        <>
          <Drawer
            anchor="right"
            open={updateEntityOpened}
            onClose={() => setUpdateEntityOpened(false)}
            PaperProps={{
              sx: [
                {
                  backgroundColor: "#F3F7FE",
                },
                (theme) => ({
                  [theme.breakpoints.down("md")]: {
                    width: `100%`,
                  },
                  [theme.breakpoints.up("md")]: {
                    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
                  },
                }),
              ],
            }}
          >
            {/* <UpdateEntityForm
              entity={entity!}
              enumerables={enumerables!}
              reviewers={reviewers!}
              onClose={() => setUpdateEntityOpened(false)}
            /> */}
          </Drawer>
        </>
      </PermissionGuard>
    </>
  );
};
