import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";
import { BasicTextField, ReviewerEntityField } from "../../../../forms";
import {
  sendForApprovalFormValuesInitialValue,
  SendForApprovalFormValues,
  sendForApprovalFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";
import { PermissionGuard, usePermissions } from "../../../../models/auth";
import {
  ApiUpdateEntity,
  ApiEntity,
  mapSendForApprovalFormValuesToApiUpdateEntity,
  useUpdateEntityMutation,
} from "../../../../models/entities";
import { ApiUserResponse } from "../../../../models/users";
import { ApiEnumeration } from "../../../../models/enumerations";
interface SendForApprovalFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  reviewers: ApiUserResponse[];
  onClose: () => void;
}
export const SendForApprovalForm: FC<SendForApprovalFormProps> = ({
  entity,
  enumerables,
  reviewers,
  onClose,
}) => {
  const [updateEntity] = useUpdateEntityMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  return (
    <Formik<SendForApprovalFormValues>
      enableReinitialize
      initialValues={{
        ...sendForApprovalFormValuesInitialValue(
          entity,
          enumerables,
          reviewers
        ),
      }}
      validationSchema={sendForApprovalFormValidationSchema(
        hasPermission("ADMIN")
      )}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiUpdateEntity: ApiUpdateEntity =
            mapSendForApprovalFormValuesToApiUpdateEntity(values);
          const apiEntity: ApiEntity = await updateEntity(
            apiUpdateEntity
          ).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.updateRecord")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <PermissionGuard permission="ADMIN">
                <Field
                  name={`id`}
                  component={BasicTextField}
                  label="common.id"
                  margin="normal"
                  disabled
                />
              </PermissionGuard>

              <Field
                name={`reviewer`}
                component={ReviewerEntityField}
                label="common.approver"
                // size="small"
                margin="normal"
              />

              <Box sx={{ mt: "24px" }}>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t("common.update")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
