import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Field, Formik, getIn } from "formik";
import React, { FC } from "react";
import toast from "react-hot-toast";

import {
  amlFormValuesInitialValue,
  AmlFormValues,
  amlFormValidationSchema,
} from ".";

import { useTranslation } from "react-i18next";

import {
  BasicTextField,
  CountryField,
  DateField,
  EnumerableField,
  MoneyField,
  YES,
  YesNoField,
} from "../../../../../../forms";
import { usePermissions } from "../../../../../../models/auth";
import {
  ApiEntity,
  ApiStageAml,
  PENDING_STAGE_STATUS,
  DRAFT_STAGE_STATUS,
  mapAmlFormValuesToApiStageAml,
  useUpdateEntityStageMutation,
  STAGE_3_1_TYPE,
  PENDING_APPROVAL_STAGE_STATUS,
} from "../../../../../../models/entities";
import {
  ApiEnumeration,
  CURRENCY,
} from "../../../../../../models/enumerations";
import { ApiUserResponse } from "../../../../../../models/users";
interface AmlFormProps {
  entity: ApiEntity;
  enumerables: ApiEnumeration[];
  currency: ApiEnumeration;
  onClose: () => void;
  profile: ApiUserResponse | undefined;
}
export const AmlForm: FC<AmlFormProps> = ({
  entity,
  enumerables,
  currency,
  onClose,
  profile,
}) => {
  const [updateEntityStage] = useUpdateEntityStageMutation();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Formik<AmlFormValues>
      enableReinitialize
      initialValues={{
        ...amlFormValuesInitialValue(entity, enumerables, currency),
      }}
      validationSchema={amlFormValidationSchema(hasPermission("ADMIN"))}
      onSubmit={async (values): Promise<void> => {
        try {
          const apiAml: ApiStageAml = mapAmlFormValuesToApiStageAml(
            entity,
            values
          );
          // const apiEntity: ApiStageBasicDetail =
          await updateEntityStage(apiAml).unwrap();

          toast.success(t("common.updatedSuccessfully"));
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched,
      }): JSX.Element => (
        <>
          {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px 38px 24px 32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ mr: 2 }}
                aria-label="close"
                size="medium"
                onClick={() => onClose()}
              >
                <Close color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography color={"textPrimary"} variant="h5">
                {t("common.contacts")}
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: "32px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Field
                name={`refNo`}
                component={BasicTextField}
                label="common.refNo"
                // size="small"
                margin="normal"
              />
              <Field
                name={"transactionDate"}
                label="common.transactionDate"
                component={DateField}
              />
              <Field
                name={`transactionDescription`}
                component={BasicTextField}
                label="common.transactionDescription"
                // size="small"
                margin="normal"
              />
              <Field
                name="transactionAmount"
                label="common.transactionAmount"
                component={MoneyField}
                margin="normal"
              />
              <Field
                name={`originalTransactionCurrency`}
                component={EnumerableField}
                type={CURRENCY}
                margin="normal"
                label="common.originalTransactionCurrency"
              />
              <Field
                name="foreignAmount"
                label="common.foreignAmount"
                component={MoneyField}
                margin="normal"
              />
              <Field
                name={`transactionLocation`}
                component={BasicTextField}
                label="common.transactionLocation"
                // size="small"
                margin="normal"
              />
              <Field
                name={`role`}
                component={BasicTextField}
                label="common.role"
                // size="small"
                margin="normal"
              />
              <Field
                name={`firstName`}
                component={BasicTextField}
                label="common.recordFirstName"
                // size="small"
                margin="normal"
              />
              <Field
                name={`entityLastName`}
                component={BasicTextField}
                label="common.lastName"
                // size="small"
                margin="normal"
              />

              <Field
                name={`country`}
                component={CountryField}
                label="common.country"
                // size="small"
                margin="normal"
              />
              <Field
                name={`reasonTransactionSuspicious`}
                component={BasicTextField}
                label="common.reasonTransactionSuspicious"
                // size="small"
                margin="normal"
              />
              <Field
                name={`actionsTakenTransactionDetail`}
                component={BasicTextField}
                label="common.actionsTakenTransactionDetail"
                // size="small"
                margin="normal"
              />
              <Field
                name={`hasTransactionLegitimate`}
                component={YesNoField}
                label="common.hasTransactionLegitimate"
                // size="small"
                margin="normal"
              />
              {getIn(values, "hasTransactionLegitimate") === YES && (
                <Field
                  name={`rationaleForConclusion`}
                  component={BasicTextField}
                  label="common.rationaleForConclusion"
                  // size="small"
                  margin="normal"
                />
              )}
              <Field
                name={"dateOfReport"}
                label="common.dateOfReport"
                component={DateField}
              />
              <Field
                name={`hasMlroAgreementPreparer`}
                component={YesNoField}
                label="common.hasMlroAgreementPreparer"
                // size="small"
                margin="normal"
              />
              {getIn(values, "hasMlroAgreementPreparer") === YES && (
                <Field
                  name={`rationaleMlroConclusion`}
                  component={BasicTextField}
                  label="common.rationaleMlroConclusion"
                  // size="small"
                  margin="normal"
                />
              )}
              <Field
                name={`hasFurtherEvidence`}
                component={YesNoField}
                label="common.hasFurtherEvidence"
                // size="small"
                margin="normal"
              />
              {getIn(values, "hasFurtherEvidence") === YES && (
                <Field
                  name={`furtherEvidenceInfo`}
                  component={BasicTextField}
                  label="common.furtherEvidenceInfo"
                  // size="small"
                  margin="normal"
                />
              )}
              <Field
                name={"dateMlroReview"}
                label="common.dateMlroReview"
                component={DateField}
              />
              <Field
                name={`hasInternalReportInstigatedSuspiciousTransaction`}
                component={YesNoField}
                label="common.hasInternalReportInstigatedSuspiciousTransaction"
                // size="small"
                margin="normal"
              />
              <Field
                name={"dateStrSubmission"}
                label="common.dateStrSubmission"
                component={DateField}
              />
              <Field
                name={`outcomeStr`}
                component={BasicTextField}
                label="common.outcomeStr"
                // size="small"
                margin="normal"
              />
              <Box
                sx={{
                  mt: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue("stageStatus", DRAFT_STAGE_STATUS);
                  }}
                >
                  {t("common.saveAsDraft")}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    // Get Stage Data
                    const stageData: ApiStageAml = entity.stages.find(
                      (s) => s.stageType === STAGE_3_1_TYPE
                    ) as ApiStageAml;

                    if (
                      profile?.id === entity.reviewerId &&
                      stageData.stageStatus === PENDING_APPROVAL_STAGE_STATUS
                    ) {
                      setFieldValue(
                        "stageStatus",
                        PENDING_APPROVAL_STAGE_STATUS
                      );
                    } else {
                      setFieldValue("stageStatus", PENDING_STAGE_STATUS);
                    }
                  }}
                >
                  {t("common.submit")}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};
